import React, { useEffect, useState, useRef } from 'react';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import './../../firebase'
import ProgressWheel from '../../Tools/ProgressWheel';

const ThumbnailSelector = ({ videoName, onThumbnailSelect, draftThumbnail }) => {
  const [thumbnails, setThumbnails] = useState([
    { id: 1, imageUrl: '../no-thumbnail.png', selected: true },
    { id: 2, imageUrl: '../no-thumbnail.png', selected: false },
    { id: 3, imageUrl: '../no-thumbnail.png', selected: false },
  ]);
  const hasRunOnceRef = useRef(false);
  const [uploadedThumbnail, setUploadedThumbnail] = useState(null);
  const fileInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [videoUploadFinished, setVideoUploadFinished] = useState(false);

  useEffect(() => {
    if (!hasRunOnceRef.current && videoName && videoName !== '') {
      setVideoUploadFinished(true);
      hasRunOnceRef.current = true;
      getDownloadURLFromFirebase();
    }

  }, [videoName]);

  const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const getDownloadURLFromFirebase = async () => {
    console.log(videoName);
    // Get videoName from firebase video path since its a draft
    if (videoName.includes("firebase")) {
      // Decode the path and extract the filename
      const url = new URL(videoName);
      const decodedPath = decodeURIComponent(url.pathname);
      const filename = decodedPath.split('/').pop();
      videoName = filename;
    }
    console.log(videoName);
    try {
      const fileName = videoName.replace(/^videos\//, '').replace(/\.mp4$/, '');
      const folder = '/thumbnails/';

      const storageRef = getStorage();

      const updatedThumbnails = [];

      for (const thumbnail of thumbnails) {
        const i = thumbnail.id;
        const finalPath = folder + `thumb_${fileName}_${i}.jpg`;
        const fileRef = ref(storageRef, finalPath);

        let url;
        let attempts = 0;
        const maxAttempts = 5;
        console.log(finalPath);
        console.log(fileRef);
        while (!url && attempts < maxAttempts) {
          try {
            url = await getDownloadURL(fileRef);
          } catch (error) {
            // Ignore errors and retry
          }

          if (!url) {
            attempts += 1;
            await wait(2000); // Wait for 2 seconds before the next attempt
          }
        }

        // If there's a draftThumbnail, add it as the first thumbnail (once)
        if (draftThumbnail && !updatedThumbnails.some(thumb => thumb.id === 1)) {
          updatedThumbnails.push({ ...thumbnail, id: 0, imageUrl: draftThumbnail, selected: false });

          // Set selected to false for all other thumbnails
          updatedThumbnails.slice(1).forEach(thumb => {
            thumb.id = thumb.id + 1;
            thumb.selected = false;
          });
        }

        if (url) {
          if (draftThumbnail) updatedThumbnails.push({ ...thumbnail, imageUrl: url, selected: false });
          else updatedThumbnails.push({ ...thumbnail, imageUrl: url });

        } else {
          console.warn(`Thumbnail ${i} not found after ${maxAttempts} attempts.`);
        }


      }

      // Update the state with the new thumbnails

      // Select the first valid thumbnail
      if (updatedThumbnails.length > 0) {
        onThumbnailSelect(updatedThumbnails[0]);
        updatedThumbnails[0].selected = true;
        setThumbnails(updatedThumbnails);
      } else {
        // if it failed to fetch automated thumbnails, set to emtpy
        onThumbnailSelect('');
      }

      setIsLoading(false);
    } catch (error) {
      console.error('Error getting download URLs:', error);
    }
  };

  const handleThumbnailSelection = (thumbnailId) => {
    const selectedThumbnail = thumbnails.find((thumbnail) => thumbnail.id === thumbnailId);

    if (selectedThumbnail) {
      setThumbnails((prevThumbnails) =>
        prevThumbnails.map((thumbnail) => ({
          ...thumbnail,
          selected: thumbnail.id === thumbnailId,
        }))
      );
      onThumbnailSelect(selectedThumbnail); // Invoke the callback with the selected thumbnail
    }
  };

  const handleThumbnailUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      // Handle the uploaded thumbnail file as needed
      // Update state to reflect the new uploadedThumbnail
      setUploadedThumbnail(URL.createObjectURL(file));
      const newThumbnail = { id: 1, imageUrl: file, renderImg: URL.createObjectURL(file), uploadedFile: file, selected: true };

      // Add the uploaded thumbnail at the beginning without removing the original 1
      setThumbnails((prevThumbnails) => [
        newThumbnail,
        ...prevThumbnails.map((thumbnail, index) => ({
          ...thumbnail,
          id: index + 2, // Increase the id for others
          selected: false,
        })),
      ]);
      onThumbnailSelect(newThumbnail);
    }
  }


  return (
    <>
      <div className='flex justify-between'>
        <div>
          <p>Choose Thumbnail</p>

        </div>
        <div className="upload-thumbnail-button text-sm cursor-pointer border-2 border-dashed border-blue-500 rounded p-2 text-center" onClick={() => fileInputRef.current.click()}>
          Upload
        </div>

        {/* Upload Thumbnail Button */}
        <input
          type="file"
          accept="image/*"
          onChange={handleThumbnailUpload}
          style={{ display: 'none' }}
          ref={fileInputRef}
        />
      </div>
      {isLoading && !uploadedThumbnail ? (
        <div className='flex justify-center items-center py-5 min-h-[100px]'>

          {!videoUploadFinished && (
            <div className='text-[12px] mr-2 text-center '>
              Wait for video upload to end <br /> to fetch free thumbnails...
            </div>
          )}


          <div className='w-15'>
            <ProgressWheel />
          </div>
        </div>

      ) : (
        <div className="thumbnail-section flex my-2 items-center  max-w-full overflow-y-hidden overflow-x-auto">
          {thumbnails.map((thumbnail) => (
            <div
              key={thumbnail.id}
              className={`thumbnail-container relative cursor-pointer flex-shrink-0 w-[150px] ${thumbnail.selected ? 'border-4 border-blue-500' : 'p-1 opacity-70'}`}
              onClick={() => handleThumbnailSelection(thumbnail.id)}
            >
              {thumbnail.renderImg ? (
                <img src={thumbnail.renderImg} alt={`Thumbnail ${thumbnail.id}`} className="w-full object-cover" />
              ) : (
                <img src={thumbnail.imageUrl} alt={`Thumbnail ${thumbnail.id}`} className="w-full object-cover" />
              )}
            </div>
          ))}
        </div>
      )}
    </>

  );
};

export default ThumbnailSelector;
