import React, { useState } from 'react'
import "./PasswordInput.css"
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';

const PasswordInput = ({name, value, onChange, onPaste}) => {
    const [showPassword, setshowPassword] = useState(false);

    const toggleShowPassword = () => {
        setshowPassword(!showPassword);
    }
  return (
    <div className='password-input-container flex items-center'>
            <input
              type={showPassword ? "text" : "password"}
              name={name}
              value={value}
              onChange={onChange}
              onPaste={onPaste}
              autoComplete="off"
              required
              className="pl-2 w-full text-black rounded-md border-0 min-h-[40px] shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />

            <div className='icon' onClick={toggleShowPassword}>
                {showPassword ? (
                    <AiFillEye size={20}/>
                ) : (
                    <AiFillEyeInvisible size={20}/>
                )}
            </div>
    </div>
  )
}

export default PasswordInput