import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BsInstagram, BsYoutube, BsTiktok } from 'react-icons/bs';
import Tabs from '../../components/Navigation/MenuTabs/Tabs';
import { FaShare } from 'react-icons/fa';
import Pagination from "../../components/Tools/Pagination";
import { formatScheduleDate } from '../../components/Tools/Time';
import { MdScheduleSend } from "react-icons/md";
import LoadingIndicator from '../../components/Tools/LoadingIndicator';
import DisplayError from '../../components/Tools/DisplayError';
import Breadcrumbs from '../../components/Tools/Breadcrumbs';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const History = () => {
  const [activeTab, setActiveTab] = useState('all');
  const [activeMenu, setActiveMenu] = useState('uploaded');
  const navigate = useNavigate();  // Use useHistory
  const hasRunOnceRef = useRef(false)
  const [isLoadingUploads, setIsLoadingUploads] = useState(true);

  //tabs API request
  const [youtubeUploads, setYoutubeUploads] = useState([]);
  const [tiktokUploads, setTiktokUploads] = useState([]);
  const [instagramUploads, setInstagramUploads] = useState([]);

  const [allUploads, setAllUploads] = useState([]);
  const [scheduled, setScheduled] = useState([]);
  const [drafts, setDrafts] = useState([]);
  const [failed, setFailed] = useState([]);

  const tabs = [
    { id: 'all', label: 'All', icon: '' },
    { id: 'youtube', label: '', icon: <BsYoutube size={20} color='red' /> },
    { id: 'instagram', label: '', icon: <BsInstagram size={20} color='#cd486b' /> },
    { id: 'tiktok', label: '', icon: <BsTiktok size={20} color='black' /> }
  ];

  const [siteMenu, setSiteMenu] = useState([
    { id: 'failed', label: 'Failed', count: { lengt: 0, color: 'bg-red-500' } },
    { id: 'uploaded', label: 'Uploaded', count: { lengt: 0, color: 'bg-green-300' } },
    { id: 'scheduled', label: 'Scheduled', count: { lengt: 0, color: 'bg-yellow-500' } },
    { id: 'draft', label: 'Drafts', count: { lengt: 0, color: 'bg-blue-500' } },
  ]);

  const handleTabChange = (newTab) => {
    setActiveTab(newTab); // Update the active tab when a tab is changed
  };
  const handleSitieMenuChange = (newTab) => {
    setActiveMenu(newTab); // Update the active tab when a tab is changed
  };



  useEffect(() => {
    if (!hasRunOnceRef.current) {

      const getAllUploads = async () => {
        try {
          const response = await axios.get(BACKEND_API_URL + '/api/auth/user/drafts', {
            withCredentials: true, // Include this option if you need cookies
          });
          if (response.status !== 200) return;
          // Separate the data based on socialMedia
          const uploadsData = response.data || [];
          const scheduled = uploadsData.filter((item) => item.status === 'scheduled');
          const drafts = uploadsData.filter((item) => item.status === 'draft');
          const failed = uploadsData.filter((item) => item.status === 'failed');
          setScheduled(scheduled);
          setDrafts(drafts);
          setFailed(failed);

          updateTabCount(response.data)
          if (failed && failed.length > 0) {
            setActiveMenu('failed');
          }
          return response.data; // Return the data for chaining
        } catch (error) {
          console.log(error);
        }
      }
      (async () => {
        try {
          const all = await getAllUploads();
          await filterUploadsOnSocial(all);
          setAllUploads(all);
        } catch (error) {
          console.error('Failed to fetch uploads!:', error);
        }
        hasRunOnceRef.current = true;
      })();
    }
  }, []);

  const posts = useMemo(() => {
    switch (activeTab) {
      case "all":
        return allUploads;
      case "youtube":
        return youtubeUploads;
      case "instagram":
        return instagramUploads;
      case "tiktok":
        return tiktokUploads;
      default:
        return allUploads;
    }
  }, [activeTab, allUploads, youtubeUploads, instagramUploads, tiktokUploads]);

  const filterUploadsOnSocial = async (uploads) => {
    const allPosts = uploads;

    let sortedDrafts = allPosts
      .map(group => {
        const latestPost = group.posts.reduce((latest, post) => {
          return new Date(post.updatedAt) > new Date(latest.updatedAt) ? post : latest;
        }, group.posts[0]);
        return { ...group, latestUpdatedAt: new Date(latestPost.updatedAt) };
      })
      .sort((a, b) => b.latestUpdatedAt - a.latestUpdatedAt);

    // Separate the data based on socialMedia
    const youtubeUploads = sortedDrafts.filter(group =>
      group.posts.some(post => post.uploadTo === 'youtube')
    );
    const tiktokUploads = sortedDrafts.filter(group =>
      group.posts.some(post => post.uploadTo === 'tiktok')
    );
    const instagramUploads = sortedDrafts.filter(group =>
      group.posts.some(post => post.uploadTo === 'instagram')
    );

    // Set the state variables
    setYoutubeUploads(youtubeUploads);
    setTiktokUploads(tiktokUploads);
    setInstagramUploads(instagramUploads);
    setIsLoadingUploads(false);
    return sortedDrafts;
  }


  const updateTabCount = async (mergedData) => {
    const tabCounts = {
      draft: 0,
      scheduled: 0,
      uploaded: 0,
      failed: 0,
    };

    mergedData.forEach(item => {
      if (item.status === 'draft') {
        tabCounts.draft++;
      } if (item.status === 'scheduled') {
        tabCounts.scheduled++;
      } if (item.status === 'uploaded') {
        tabCounts.uploaded++;
      } else if (item.status === 'failed') {
        tabCounts.failed++;
      }
    });

    const hasFailedDrafts = tabCounts.failed > 0;

    setSiteMenu(prevTabs => {
      const updatedTabs = prevTabs.map(tab => {
        if (tab.id in tabCounts) {
          return { ...tab, count: { ...tab.count, length: tabCounts[tab.id] } };
        }
        return tab;
      });

      if (!hasFailedDrafts) {
        setActiveMenu('uploaded')
        return updatedTabs.filter(tab => tab.id !== 'failed');
      } else {
        setActiveMenu('failed')
      }

      return updatedTabs;
    });
  }

  const handleReuploadClick = (upload) => {
    navigate(`/dashboard/upload`, { state: { upload } });
  }


  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;
  const [maxPage, setMaxPage] = useState(null);
  const [currentItems, setCurrentItems] = useState([]);

  useEffect(() => {
    const paginate = (items) => {
      const indexOfLastItem = currentPage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);
      const maxPage = Math.ceil(items.length / itemsPerPage);
      return { currentItems, maxPage };
    };

    let paginationResult = { currentItems: [], maxPage: 1 };

    switch (activeMenu) {
      case 'uploaded':
        paginationResult = paginate(posts);
        break;
      case 'scheduled':
        paginationResult = paginate(scheduled);
        break;
      case 'draft':
        paginationResult = paginate(drafts);
        break;
      case 'failed':
        paginationResult = paginate(failed);
        break;
      default:
        paginationResult = paginate(allUploads);

        break;
    }

    const { currentItems, maxPage } = paginationResult;
    setCurrentItems(currentItems);
    setMaxPage(maxPage);
  }, [activeTab, currentPage, allUploads, activeMenu, itemsPerPage]);



  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= maxPage) {
      setCurrentPage(newPage);
    }
  };


  return (
    <div className='dashboard-container container'>
      {isLoadingUploads === true ? (
        <LoadingIndicator />
      ) : (
        <div className='flex flex-col gap-2'>
          <Breadcrumbs />

          <div className='dashboard-section w-max'>
            <Tabs tabs={siteMenu} onTabChange={handleSitieMenuChange} activeTab={activeMenu} main={true} />
          </div>


          <div className='flex gap-2 items-start relative'>
            {activeMenu !== 'draft' && (
              <div className='dashboard-section !m-0 !w-max !px-0 !py-3'>
                <Tabs tabs={tabs} onTabChange={handleTabChange} activeTab={activeTab} main={false} useResponsiveDropdown={false} />
              </div>
            )}


            {activeMenu === 'uploaded' && allUploads.length > 0 && (
              <RenderUploads currentItems={currentItems} onReuploadClick={handleReuploadClick} />
            )}
            {activeMenu === 'scheduled' && (
              <RenderUploads currentItems={scheduled} onReuploadClick={handleReuploadClick} />
            )}
            {activeMenu === 'draft' && (
              <RenderUploads currentItems={drafts} onReuploadClick={handleReuploadClick} />
            )}
            {activeMenu === 'failed' && (
              <RenderUploads currentItems={failed} onReuploadClick={handleReuploadClick} />
            )}

            {maxPage > 0 && (
              <div className='self-end absolute top-[-50px] right-0'>
                <Pagination currentPage={currentPage} maxPage={maxPage} onPageChange={handlePageChange} />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
const RenderUploads = ({ currentItems, onReuploadClick }) => {
  const handleReupload = (upload) => {
    onReuploadClick(upload);
  };

  const handleImageError = (event) => {
    event.target.src = `../no-thumbnail.png`; // Replace with the path to your alternative image
  };

  return (
    <>
      {currentItems && currentItems.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 w-full">
          {currentItems && currentItems.length > 0 && currentItems.map((group, index) => (
            <div key={group.post_id + index} className="flex h-[200px] relative rounded-lg transform transition-transform hover:scale-105">
              <div className="rounded-lg overflow-hidden relative w-full">
                <div className="absolute inset-0 bg-black opacity-40"></div>
                {group.posts[0].thumbnail ? (
                  <img onError={handleImageError} src={group.posts[0].thumbnail} alt='Thumbnail' loading="lazy" className="w-full h-full object-cover" />
                ) : (
                  <img src="../no-thumbnail.png" alt="No Thumbnail" loading="lazy" className="w-full h-full object-cover opacity-0" />
                )}
                <div className="absolute top-0 left-0 right-0 flex justify-between items-start p-2 text-white">
                  <div className='opacity-40'>
                    <p className="--small-text">{formatScheduleDate(group.posts[0].createdAt)}</p>
                  </div>
                  <div className='flex'>
                    {group.posts.map((post, index) => (
                      <div className='px-2' key={index}>
                        <IconForSocialMedia socialMedia={post.uploadTo} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="absolute flex justify-between gap-3 px-3 py-3 bottom-0 text-white w-full bg-gray-900 rounded-b-lg min-h-[60px] max-h-[60px]">
                <div className='flex flex-col overflow-hidden flex-1'>
                  {group.posts[0].title !== "" && (
                    <h3 className="text-md font-semibold truncate">{group.posts[0].title}</h3>
                  )}
                  <p className='text-[12px] overflow-hidden whitespace-nowrap overflow-ellipsis'>{group.posts[0].description}</p>
                </div>
                <div className='flex flex-col text-[10px] italic '>
                  <div className='flex items-center justify-end mb-2 w-[85px] border-l'>
                    {group.status === 'scheduled' && group.posts[0].schedule && (
                      <p className="--small-text ml-2">{formatScheduleDate(group.posts[0].schedule)}</p>
                    )}
                    {group.status === 'uploaded' && (
                      <p className="--small-text ml-2">{formatScheduleDate(group.posts[0].updatedAt)}</p>
                    )}
                    <div className='flex flex-col gap-2'>
                      <MdScheduleSend className='ml-2 opacity-50' size={15} />
                      <div
                        className="p-1 rounded-sm bg-blue-500 cursor-pointer hover:bg-blue-600 text-[10px] flex ml-auto"
                        onClick={() => { handleReupload(group); }}
                      >
                        <FaShare size={10} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <DisplayError />
      )}
    </>
  );
};


const IconForSocialMedia = (socialMedia) => {
  // Dunno why its a property
  switch (socialMedia.socialMedia) {
    case 'youtube':
      return <BsYoutube color='red' />;
    case 'instagram':
      return <BsInstagram color='#C13584' />;
    case 'tiktok':
      return <BsTiktok color='black' />;
    default:
      return null;
  }
};

export default History