import React, { useState } from 'react';
import './GoogleLoginButton.css'; // Create this CSS file
import axios from 'axios';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const YoutubeLoginButton = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleAuthClick = () => {
    // Call the authorize function to generate the authorization URL
    setIsLoading(true);
    axios.post(BACKEND_API_URL + '/api/google/oauth',  {} , { withCredentials: true })
      .then(response => {
        window.open(response.data, '_blank');
        setIsLoading(false);
      })
      .catch(error => {
        console.log(error);
      });
  };



  return (
    <><button className="google-login-button social-media-buttons-style" onClick={handleAuthClick} disabled={isLoading}>
      {isLoading ? 'Loading...' : (
        <>
          <img src={'../socials/youtube-logo.svg'} alt="Creator Avatar" className="creator-avatar mr-3" />
          <p className='button-text-tiktok'>
            {'Youtube'}
          </p>
        </>
      )}
    </button></>
  );
}
export default YoutubeLoginButton;
