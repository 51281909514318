import { useEffect, useState } from 'react';

const Tabs = ({ tabs, onTabChange, activeTab, main, useResponsiveDropdown = true }) => {
  const [selectedTab, setSelectedTab] = useState('');

  useEffect(() => {
    setSelectedTab(activeTab);
  }, [activeTab]);

  const handleTabChange = (tabId) => {
    setSelectedTab(tabId);
    onTabChange(tabId);
  };

  return (
    <>
      {useResponsiveDropdown && (
        <div className="sm:hidden">
          <label htmlFor="Tab" className="sr-only">
            MenuTabs
          </label>

          <select
            id="Tab"
            className="w-full rounded-md"
            value={selectedTab}
            onChange={(e) => handleTabChange(e.target.value)}
          >
            {tabs.map((tab) => (
              <option key={tab.id} value={tab.id}>
                {tab.label || tab.icon}
              </option>
            ))}
          </select>
        </div>
      )}

      <div className={`${useResponsiveDropdown ? 'hidden sm:block' : 'block'} ${main === true && 'border-b border-gray-200'} cursor-pointer select-none`}>
        <nav className={`flex items-center gap-6 ${main ? 'flex' : 'flex flex-col'}`} aria-label="Tabs">
          {tabs.map((tab) => (
            <TabButton
              key={tab.id}
              icon={tab.icon}
              label={tab.label}
              selected={selectedTab === tab.id}
              main={main}
              onClick={() => handleTabChange(tab.id)}
              count={tab.count}
            />
          ))}
        </nav>
      </div>
    </>
  );
};

const TabButton = ({ icon, label, selected, onClick, main, count }) => {
  return (
    <div
      className={`px-4 ${main ? 'border-b-2 pb-2' : ''} ${icon && 'flex gap-2 items-center'} text-sm font-medium ${selected
        ? 'border-sky-500 text-sky-600'
        : 'border-transparent hover:border-gray-300'
        }`}
      onClick={onClick}
    >
      <div className={`flex gap-2 ${!selected && 'opacity-30'} relative`}>
        <span className={`flex gap-2 items-center ${!main && 'flex-col text-[10px]'}`}>{icon}{label}</span>
        {count && (
          <span className={`absolute right-[-15px] text-white top-[-10px] px-2 rounded-full text-[10px] text-bold ${count.color}`}>
            {count.length > 99 ? '99+' : count.length}
          </span>)}
      </div>
    </div>
  );
};

export default Tabs;
