import React from "react";
import Chart from "react-apexcharts";

const BarChart = () => {
    const chartOptions = {
        series: [
            {
                name: 'Actual',
                data: [
                    {
                        x: 'Uploads',
                        y: 5,
                        goals: [
                            {
                                name: 'Expected',
                                value: 10,
                                strokeWidth: 2,
                                strokeDashArray: 2,
                                strokeColor: '#775DD0'
                            }
                        ]
                    },
                    {
                        x: 'Scheduled',
                        y: 3,
                    },
                    {
                        x: 'Drafts',
                        y: 5,
                    }]
            }
        ],
        options: {
            chart: {
                height: 350,
                type: 'bar',
                toolbar: {
                    show: false
                },
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    distributed: true,
                }
            },
            colors: ['#00E396', '#eab308', '#3b82f6'],
            dataLabels: {
                formatter: function (val, opt) {
                    const goals =
                        opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex]
                            .goals

                    if (goals && goals.length) {
                        return `${val} / ${goals[0].value}`
                    }
                    return val
                }
            },
            legend: {
                show: true,
                showForSingleSeries: true,
                customLegendItems: ['Actual', 'Expected'],
                markers: {
                    fillColors: ['#00E396', '#775DD0']
                }
            },
            title: {
                text: 'Monthly Statistics',
                align: 'center',
                style: {
                    fontSize: '20px',
                    fontWeight: '600',
                    fontFamily: 'Poppins, sans-serif',
                    letterSpacing: '0.5px',
                    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)'
                },
            },
        },
    };


    return (
        <div>
            <div id="chart">
                <Chart options={chartOptions.options} series={chartOptions.series} type={chartOptions.options.chart.type} width={'100%'} height={300} />
            </div>
        </div>
    );
}

export default BarChart;
