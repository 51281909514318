import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { IoIosClose } from 'react-icons/io';
import PasswordInput from '../Password/PasswordInput';
import { useNavigate } from 'react-router-dom';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;


const ResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState(false);
  const navigate = useNavigate();
  const notLoggedIn = false;

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    code: ''
  });
  const { email, password, code } = formData;

  // Pass Rules
  const [num, setNum] = useState(false)
  const [passLength, setPassLength] = useState(false)

  useEffect(() => {
    // Check for number
    if (password.match(/([0-9])/) && password.match(/([0-9])/)) {
      setNum(true);
    } else {
      setNum(false);
    }

    // Check for password length
    if (password.length >= 6) {
      setPassLength(true);
    } else {
      setPassLength(false);
    }
  }, [password])

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      var sendEmail = email;

      //cant get user, since not logged in. Get EMAIL FROM STEP ONE
      if (!notLoggedIn) {
        const getEmail = await axios.get(BACKEND_API_URL + '/api/auth/user/getUser', {
          withCredentials: true,
        });
        sendEmail = getEmail.data.email;
      }
      const changePass = await axios.patch(BACKEND_API_URL + '/api/auth/changepassword/newpassword', { email: sendEmail, password, code }, {
        withCredentials: true, // Include this option if you need cookies
      });
      if (notLoggedIn) {
        setResponseMessage('<p class="--success-message">Successfull changed your password, you will be redirected to the login page, or click the <Link to="/login">Login</Link></p>');
        navigate('/login')
      }
      setResponseMessage('<p class="--success-message">Successfull changed your password!</p>');

      setIsLoading(false);
      return changePass.data; // Return the data for chaining
    } catch (error) {
      console.log(error);
      setResponseMessage(`<p class="--error-message">${error.response.data.error}</p>`);
    }
  };



  return (
    <div className="auth-form-container">
      <div className="auth-form">
        <form onSubmit={handleSubmit}>
          <div>
            <h2>Did you forget your password?</h2>
            <p>Enter the verification code that you received in your email and your choose your new password:</p>
            <div className='password-input-container mb-2'>
              <input
                type="text"
                name="code"
                placeholder='Verification Code'
                id="code"
                value={code}
                onChange={handleChange}
                required
                className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>

            <PasswordInput placeholder={"New Password"} name={"password"} value={password} onChange={handleChange} />

            {/* Password Strength */}
            {password.length > 0 && !num && (
              <div className='passwordStrength'>
                <IoIosClose color='red' fontSize={30} /> Has to include a number
              </div>
            )}
            {password.length > 0 && !passLength && (
              <div className='passwordStrength'>
                <IoIosClose color='red' fontSize={30} /> At least 6 chars
              </div>
            )}
            <button
              className={`upload-button ${isLoading ? 'disabled' : ''} mt-2`}
              disabled={isLoading}
            >
              {isLoading ? (
                <div className="loading-animation"></div>
              ) : (
                <span>Submit</span>
              )}
            </button>
            {responseMessage && (<div dangerouslySetInnerHTML={{ __html: responseMessage }}></div>)}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;