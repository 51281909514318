import { initializeApp } from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDpjEdKSH9qFKAqE5QImMZgnSO5OMzO4uY",
  authDomain: "socialshare-6946b.firebaseapp.com",
  projectId: "socialshare-6946b",
  storageBucket: "socialshare-6946b.appspot.com",
  messagingSenderId: "568132719162",
  appId: "1:568132719162:web:9936f41a0128e4a98adb03"
};

// Initialize Firebase
initializeApp(firebaseConfig);