import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './AuthForm.css'; // Import the CSS file for styling
import PasswordInput from './Password/PasswordInput';
import { IoIosClose } from 'react-icons/io';
import axios from 'axios';
import SendEmail from '../Tools/SendEmail'
import Welcome from '../../emails/Welcome.tsx';
import { render } from '@react-email/components';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const AuthForm = ({ isLogin, checkLoginStatus, sendOpenModal }) => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
  });
  const { name, email, password } = formData;

  //password strength
  const [num, setNum] = useState(false)
  const [passLength, setPassLength] = useState(false)

  useEffect(() => {
    // Check for number
    if (password.match(/([0-9])/) && password.match(/([0-9])/)) {
      setNum(true);
    } else {
      setNum(false);
    }

    // Check for password length
    if (password.length >= 6) {
      setPassLength(true);
    } else {
      setPassLength(false);
    }
  }, [password])

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAuthentication = async (formData, isLogin) => {
    try {
      const response = isLogin
        ? await axios.post(BACKEND_API_URL + '/api/auth/login', formData, {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        })
        : await axios.post(BACKEND_API_URL + '/api/auth/register', formData, {
          withCredentials: true,
        });

      if (response.data.error) {
        setError(response.data.error);
      } else {
        checkLoginStatus();
        if (isLogin === false) {
          const emailHtml = render(<Welcome username={formData.name} link={response.data.link} />)
          await SendEmail('Welcome to SocialFlick – Your Journey Begins Here 🚀', emailHtml);
        }

        setTimeout(() => {
          navigate('/dashboard');
        }, 1000);
      }
    } catch (error) {
      if (error.response && error.response.data.error) {
        setError(error.response.data.error);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isLogin) {
      if (!email || !password) {
        console.log('Missing user data from the form!');
        return;
      }
    } else {
      if (!email || !password) {
        console.log('Missing user data from the form!');
        return;
      }

      if (!num || !passLength) {
        console.log('Select a stronger password!');
        return;
      }
    }

    handleAuthentication(formData, isLogin);
  };

  const setIsModalOpen = (email) => {
    sendOpenModal(email);
  }
  return (
    <div className="--theme-colors flex min-h-full flex-col justify-center w-[400px] p-5 max-w-[90%] py-4 --social-cards-shadow rounded-md">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        {isLogin && (
          <img
            className="mx-auto h-[100px] w-auto"
            src="../logo/favicon-nobg.png"
            alt="logo"
          />
        )}
        <h2 className="mt-5 text-center text-2xl font-bold leading-9 tracking-tight">
          {isLogin ? 'Log in to your account' : 'Create your account'}
          {!isLogin && (
            <p className='text-sm italic font-light'>
              Currently in <u>beta</u>, free access with bugs expected!
            </p>
          )}
        </h2>
      </div>

      <div className="w-[80%] mx-auto">
        <form className="space-y-4" onSubmit={handleSubmit}>
          {!isLogin && (
            <div>
              <label htmlFor="email" className="block text-sm font-medium leading-6">
                Name *
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={name}
                  onChange={handleChange}
                  required
                  className=" pl-2 block w-full rounded-lg border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          )}
          <div>
            <label htmlFor="email" className="block text-sm font-medium leading-6">
              Email *
            </label>
            <div className="mt-2">
              <input
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={handleChange}
                required
                className="pl-2 block w-full rounded-lg border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="password" className="block text-sm font-medium leading-6">
                Password *
              </label>
              {isLogin && (
                <div className="text-sm">
                  <p className="font-semibold text-indigo-600 hover:text-indigo-500 cursor-pointer" onClick={(e) => setIsModalOpen(email)}>
                    Forgot password?
                  </p>
                </div>
              )}
            </div>
            <div className="mt-2">
              <PasswordInput name={"password"} value={password} onChange={handleChange} />
            </div>
          </div>

          {!isLogin && (
            <div className='text-[11px] text-center italic w-[80%] mx-auto'>
              <p>By signing up, you agree to our <a href='https://socialflick.io/terms/' target='_blank' rel="noreferrer" className='underline'>Terms of Service</a> and <a href='https://socialflick.io/privacy-policy/' className='underline' target='_blank' rel="noreferrer">Privacy Policy</a>.</p>
            </div>
          )}

          <div>
            <button
              type="submit"
              className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {isLogin ? 'Log in' : 'Sign up →'}
            </button>
          </div>
        </form>

        <div>
          {!isLogin && password.length > 0 && !num && (
            <div className='passwordStrength mt-2'>
              <IoIosClose color='red' fontSize={30} /> Has to include a number
            </div>
          )}
          {!isLogin && password.length > 0 && !passLength && (
            <div className='passwordStrength'>
              <IoIosClose color='red' fontSize={30} /> At least 6 chars
            </div>
          )}
        </div>
        {error &&
          <div >
            <p className='--error-message'>{error}</p>
          </div>
        }

        {isLogin && (
          <p className="mt-6 text-center text-sm text-gray-500">
            Not a member?{' '}
            <a href="/signup" rel="noreferrer" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
              Signup for free
            </a>
          </p>
        )}
      </div>

    </div>
  );
};

export default AuthForm;