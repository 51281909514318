import { ImCheckmark } from 'react-icons/im';

const Checkboxbtn = ({ name, platform, logo, status, firstSelect }) => {
  let color = '';

  if (platform === 'tiktok') {
    color = 'black';
  }
  if (platform === 'google' || platform === 'facebook') {
    color = '#1778f2';
  }

  if (platform === 'youtube') {
    color = 'red';
  }

  const handleImageError = (event, platform) => {
    // If the image fails to load, replace the source with an alternative image
    event.target.src = `../socials/${platform}-logo.svg`; // Replace with the path to your alternative image
  };

  return (
    <div className='flex flex-col'>
      <div className={`text-center truncate ${firstSelect ? 'w-[100px] text-[15px] ' : 'w-[50px] text-[10px]'}`}> {/* Adjust max-w-[150px] as needed */}
        {name}
      </div>

      <div className={`social-media-checkbox-style relative ${firstSelect ? 'w-[100px]' : 'w-[50px]'}`}>
        {status && <ImCheckmark size={15} color='green' className='absolute bottom-[-3px] right-[-3px]' />}
        <img src={!platform ? `../no-thumbnail.png` : `../socials/${platform}-logo.svg`} alt="Creator Avatar" className="absolute w-5 bottom-2 right-0 z-10" />
        <img src={!logo ? '' : `${logo}`} onError={(e) => handleImageError(e, platform)} alt="Creator Avatar" className="z-99 w-full rounded-md" />
      </div>
    </div>
  );
}

export default Checkboxbtn;