import moment from 'moment';
import 'moment-timezone';


// returns 26/6 etc with timezone ....
export const formatCustomDate = (dateString, timeZone = moment.tz.guess()) => {
    const date = moment(dateString).tz(timeZone);
    const now = moment().tz(timeZone);

    return date.format('DD/MM');
};
