import React from 'react';
import AuthForm from '../../components/Auth/AuthForm';
import { Link } from 'react-router-dom';
import { BsCheck } from 'react-icons/bs';

const SignupPage = ({ checkLoginStatus }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="w-full bg-gray-800 shadow-lg z-10 flex justify-between items-end px-8 py-4">
        <a href="https://socialflick.io">
          <img src="./logo/logo-horizontal.png" className="w-[100px]" alt="logo" />
        </a>
        <p className="text-gray-200 text-right w-[60%] sm:w-auto">
          Already have an account? <Link to="/login" className="text-blue-400">Login</Link>
        </p>
      </div>

      <div className='flex flex-col md:flex-row lg:flex-row items-center justify-center flex-1 gap-5 lg:gap-x-5'>
        <div className='flex flex-col'>
          <div className='flex flex-col justify-center items-center mb-3 !px-5'>
            <img src={`${process.env.PUBLIC_URL}/logo/favicon-nobg.png`} alt='logo' className='w-[150px]' />
            <h2 className='text-3xl font-semibold'>Welcome to SocialFlick</h2>
            <p className='italic py-1 text-center'>Embark on a smoother journey in managing your social media career with us!</p>
          </div>
          <div className='mx-auto !px-5'>
            <FeatureList />
          </div>
        </div>

        <div className="flex items-center justify-center">
          <AuthForm isLogin={false} checkLoginStatus={checkLoginStatus} />
        </div>
      </div>
    </div>
  );
}


export default SignupPage;






const FeatureList = () => {
  return (
    <div>
      <ul className="list-none">
        <li className="flex items-start py-4 border-b border-gray-200">
          <div className="mr-4">
            <BsCheck size={40} className="text-green-500" />
          </div>
          <div>
            <p className="font-semibold">Share Everywhere with One Click</p>
            <p className="text-sm text-gray-600">Easily post your content on all your favorite social media platforms with just one upload - one click.</p>
          </div>
        </li>
        <li className="flex items-start py-4 border-b border-gray-200">
          <div className="mr-4">
            <BsCheck size={40} className="text-green-500" />
          </div>
          <div>
            <p className="font-semibold">Stay Organized with an Easy-to-Use Calendar</p>
            <p className="text-sm text-gray-600">Keep track of your content schedule and never miss a post with our simple calendar feature.</p>
          </div>
        </li>
        <li className="flex items-start py-4 border-b border-gray-200">
          <div className="mr-4">
            <BsCheck size={40} className="text-green-500" />
          </div>
          <div>
            <p className="font-semibold">Easy management</p>
            <p className="text-sm text-gray-600">Save your ideas as a draft and create templates for your upcoming posts, making content creation a breeze.</p>
          </div>
        </li>
        <li className="flex items-start py-4 border-b border-gray-200">
          <div className="mr-4">
            <BsCheck size={40} className="text-green-500" />
          </div>
          <div>
            <p className="font-semibold">Customize Your Content with Templates</p>
            <p className="text-sm text-gray-600">Design and manage your own caption templates for your posts, ensuring your content and brand stands out.</p>
          </div>
        </li>
      </ul>
    </div>
  );
}