import React, { useState } from 'react';
import axios from 'axios';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const InstagramLoginButton = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleAuthClick = () => {
    // Call the authorize function to generate the authorization URL
    setIsLoading(true);
    axios.post(BACKEND_API_URL + '/api/facebook/oauth', {}, { withCredentials: true })
      .then(response => {
        window.open(response.data, '_blank');
        setIsLoading(false);
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <button className="facebook-login-button social-media-buttons-style fb-login-button" onClick={handleAuthClick} disabled={isLoading} data-scope="public_profile,email,instagram_basic">
      {isLoading ? 'Loading...' : (
        <>
          <img src={'../socials/instagram-logo.svg'} alt="Instagram Logo" className="creator-avatar mr-3" />
          <p className='button-text-tiktok'>
            {'Instagram Business'}
          </p>
        </>
      )}
    </button>
  );
}

export default InstagramLoginButton;