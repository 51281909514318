import React, { useState } from "react";
import Chart from "react-apexcharts";
import { BsThreeDotsVertical } from "react-icons/bs";
import DropdownButton from "../Tools/DropdownButton";
import { FaEye } from "react-icons/fa";

const Heatmap = ({ data, showWeekly }) => {
    const [isWeeklyView, setIsWeeklyView] = useState(showWeekly); // State to manage view toggle

    const heatmapData = isWeeklyView ? generateWeeklyHeatmapData(data) : generateHeatmapData(data);

    const chartOptions = {
        series: heatmapData,
        options: {
            chart: {
                height: 450,
                type: 'heatmap',
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                heatmap: {
                    enableShades: false,
                    colorScale: {
                        ranges: [
                            {
                                from: 0,
                                to: 0,
                                color: '#e0e0e0', // Light gray for 0 posts
                                name: 'No Posts'
                            },
                            {
                                from: 1,
                                to: 1,
                                color: '#ffeb3b', // Yellow for 1 post
                                name: '1 Post'
                            },
                            {
                                from: 2,
                                to: 2,
                                color: '#ff9800', // Orange for 3 posts
                                name: '2 Posts'
                            },
                            {
                                from: 3,
                                to: 3,
                                color: '#ff5722', // Deep Orange for 4 posts
                                name: '3 Posts'
                            },
                            {
                                from: 4,
                                to: 6,
                                color: '#e91e63', // Pink for 6 posts
                                name: '4-6 Posts'
                            },
                            {
                                from: 7,
                                to: 9,
                                color: '#673ab7', // Deep Purple for 7 posts
                                name: '7-9 Posts'
                            },
                            {
                                from: 10,
                                to: 100,
                                color: '#1a237e', // Same dark blue color for 11+ posts
                                name: '10+ Posts'
                            },
                        ],
                    },
                }
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                type: 'category',
                categories: generateDaysArray(isWeeklyView) // Generate categories based on view
            },
            title: {
                text: isWeeklyView ? 'Weekly Posting Routine for ' + capitalizeFirstLetter(new Date().toLocaleString('default', { month: 'long' })) : '6-Month Posting Routine',
                align: 'center',
                style: {
                    fontSize: '20px',
                    fontWeight: '600',
                    fontFamily: 'Poppins, sans-serif',
                    letterSpacing: '0.5px',
                    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)'
                },
            },
        },
    };

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    // Function to toggle between views
    const toggleView = () => {
        setIsWeeklyView(prevView => !prevView);
    };

    return (
        <div className="relative">
            <div className="absolute top-0 right-0 z-10">
                <DropdownButton label={<BsThreeDotsVertical size={20} />} type={'singleLeftItems'} options={[
                    { label: `${isWeeklyView ? 'Show 6-Month View' : 'Show Weekly View'}`, onClick: () => toggleView(), icon: <FaEye className="text-blue-600 font-bold" /> },
                ]} />
            </div>
            <div id="chart">
                <Chart
                    options={chartOptions.options}
                    series={chartOptions.series}
                    type={chartOptions.options.chart.type}
                    width={'100%'}
                    height={300}
                />
            </div>
        </div>
    );

}

function generateHeatmapData(postData) {
    const postCountsByDate = {};

    postData.forEach(post => {
        post.posts.forEach(subPost => {
            const date = new Date(subPost.createdAt).toISOString().split('T')[0]; // Extract date in YYYY-MM-DD format
            if (postCountsByDate[date]) {
                postCountsByDate[date] += 1; // Increment count if date already exists
            } else {
                postCountsByDate[date] = 1; // Initialize count for new date
            }
        });
    });

    const series = [];

    const today = new Date();
    const startMonth = new Date(today.getFullYear(), today.getMonth() - 5, 1); // 5 months before this month
    const endMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0); // End of the next month

    for (let month = new Date(endMonth); month >= startMonth; month.setMonth(month.getMonth() - 1)) {
        const monthData = [];
        const monthName = month.toLocaleString('default', { month: 'long' }) + ' ' + month.getFullYear();

        const daysInMonth = new Date(month.getFullYear(), month.getMonth() + 1, 0).getDate();

        for (let day = 1; day <= daysInMonth; day++) {
            const date = new Date(month.getFullYear(), month.getMonth(), day);
            const formattedDate = date.toISOString().split('T')[0]; // Format as YYYY-MM-DD
            const metricValue = postCountsByDate[formattedDate] || 0; // Use post count if available, otherwise 0

            monthData.push({ x: day.toString(), y: metricValue }); // Use day as x-axis label
        }

        series.push({
            name: monthName,
            data: monthData
        });
    }

    return series;
}

function generateWeeklyHeatmapData(postData) {
    const postCountsByDate = {};

    // Count posts by date
    postData.forEach(post => {
        post.posts.forEach(subPost => {
            const date = new Date(subPost.createdAt).toISOString().split('T')[0];
            if (postCountsByDate[date]) {
                postCountsByDate[date] += 1;
            } else {
                postCountsByDate[date] = 1;
            }
        });
    });

    const series = [];
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();

    const daysInMonth = new Date(year, month + 1, 0).getDate(); // Number of days in the current month

    // Loop for the 4 weeks of the month in reverse order
    for (let week = 4; week >= 1; week--) {  // Start from the last week and move to the first week
        const weekData = [];

        // Each week covers 7 days; calculate the start and end day for each week in reverse order
        const startDay = (week - 1) * 7 + 1;
        const endDay = Math.min(week * 7, daysInMonth); // Ensure we don't go beyond the last day of the month

        for (let day = startDay; day <= endDay; day++) {
            const date = new Date(year, month, day);
            const formattedDate = date.toISOString().split('T')[0];
            const metricValue = postCountsByDate[formattedDate] || 0; // Use post count if available, otherwise 0

            weekData.push({ x: day.toString(), y: metricValue }); // Use day as x-axis label
        }

        // Push each week as a separate series with a reversed week name
        series.push({
            name: `Week ${week}`,
            data: weekData
        });
    }

    return series;
}


function generateDaysArray(isWeekly) {
    const days = [];

    if (isWeekly) {
        for (let day = 1; day <= 7; day++) {
            days.push(day.toString()); // Convert day to string to match the x-axis format
        }
    } else {
        for (let day = 1; day <= 31; day++) {
            days.push(day.toString()); // Convert day to string to match the x-axis format
        }
    }
    return days;
}

export default Heatmap;
