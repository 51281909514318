import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Todo from '../../../components/ToDo/Todo';
import Socials from '../Socials/Socials';
import Drafts from '../../../components/Drafts/Drafts';
import { FaPlus } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import Onboarding from '../../../components/Onboarding/Onboarding';
import Guide from '../../../components/Onboarding/Guide';
import LoadingIndicator from '../../../components/Tools/LoadingIndicator';
import useLocalStorage from 'use-local-storage';
import { isMobile as checkIsMobile, handleResize, debounce } from '../../../utils/utils'; // Import utility functions
import Heatmap from '../../../components/Statistics/Heatmap';
import BarChart from '../../../components/Statistics/Bar';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

// Assets
const Dashboard = ({ checkLoginStatus }) => {
    const [allUploads, setAllUploads] = useState({ uploads: [], percChange: 0 });
    const [allSocials, setAllSocials] = useState({ num: 0, percChange: 0 });
    const [uploadedDrafts, setUploadedDrafts] = useState(0);
    const [scheduledDrafts, setScheduledDrafts] = useState(0);
    const [draftedDrafts, setDraftedDrafts] = useState(0);
    const hasRunOnceRef = useRef(false);
    const [showOnboardingModal, setShowOnboardingModal] = useState(false);
    const [userSettings, setUserSettings] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const username = useLocalStorage("username");
    const [isMobile, setIsMobile] = useState(checkIsMobile());
    const navigate = useNavigate();  // Use useHistory

    useEffect(() => {
        const debouncedHandleResize = debounce(() => handleResize(setIsMobile), 100);
        window.addEventListener("resize", debouncedHandleResize);
        return () => {
            window.removeEventListener("resize", debouncedHandleResize);
        };
    }, [isMobile]);

    useEffect(() => {
        if (!hasRunOnceRef.current) {
            hasRunOnceRef.current = true;
            checkLoginStatus();

            (async () => {
                const user = await getUser();
                const onboardingBool = await userHasCompletedOnboarding(user);

                if (onboardingBool === false) {
                    setShowOnboardingModal(true);
                }
                setIsLoading(false);
            })();

            // GET ALL DRAFTS
            (async () => {
                try {
                    const response = await axios.get(BACKEND_API_URL + '/api/auth/user/drafts', {
                        withCredentials: true, // Include this option if you need cookies
                    });
                    // Separate drafts based on status
                    const allDrafts = response.data;
                    const uploadedDrafts = allDrafts.filter(draft => draft.status === 'uploaded');
                    const scheduledDrafts = allDrafts.filter(draft => draft.status === 'scheduled');
                    const draftedDrafts = allDrafts.filter(draft => draft.status === 'draft');
                    //const failedDrafts = allDrafts.filter(draft => draft.status === 'failed');

                    setUploadedDrafts(uploadedDrafts);
                    setScheduledDrafts(scheduledDrafts);
                    setDraftedDrafts(draftedDrafts);
                    setAllUploads(response.data)
                    console.log(response.data);

                } catch (error) {
                    console.log(error);
                }
            })();

            (async () => {
                try {
                    const response = await axios.get(BACKEND_API_URL + '/api/auth/user/social_media_tokens', {
                        withCredentials: true,
                    });
                    setAllSocials(response.data);
                } catch (error) {
                    console.log(error);
                }
            })();
        }
    }, [checkLoginStatus])

    const getUser = async () => {
        try {
            const response = await axios.get(BACKEND_API_URL + '/api/auth/user/isLoggedIn', { withCredentials: true });
            return response.data.user;
        } catch (error) {
            console.error("Error checking user login status:", error);
            throw error; // Optionally re-throw the error for handling in the calling code
        }
    };

    const userHasCompletedOnboarding = async (user) => {
        const response = await axios.get(BACKEND_API_URL + '/api/auth/user/settings', { withCredentials: true });
        // IF there is no user settings OR there is usersetting with onboarding not completed, show the modal
        setUserSettings(response.data)
        if (response.data === undefined || response.data.onboarding.completed === false) return false;

        // Else the onboarding is completed, ie dont show the modal
        return true;
    };

    const onClose = () => {
        setShowOnboardingModal(false);
    }

    const handleEditDraft = (upload) => {
        navigate(`/dashboard/upload`, { state: { upload } });
    }
    const data = [
        {
            "post_id": "post1",
            "posts": [
                { "_id": "1", "post_id": "post1", "user_id": "user1", "createdAt": "2024-09-01T08:12:45.069Z" },
                { "_id": "2", "post_id": "post1", "user_id": "user1", "createdAt": "2024-09-05T09:15:30.069Z" },
                { "_id": "3", "post_id": "post1", "user_id": "user1", "createdAt": "2024-09-05T10:30:10.069Z" },
                { "_id": "3", "post_id": "post1", "user_id": "user1", "createdAt": "2024-08-05T10:30:10.069Z" },
                { "_id": "3", "post_id": "post1", "user_id": "user1", "createdAt": "2024-06-05T10:30:10.069Z" },
                { "_id": "3", "post_id": "post1", "user_id": "user1", "createdAt": "2024-06-05T10:30:10.069Z" },
                { "_id": "4", "post_id": "post1", "user_id": "user1", "createdAt": "2024-06-10T12:45:00.069Z" }
            ]
        },
        {
            "post_id": "post2",
            "posts": [
                { "_id": "5", "post_id": "post2", "user_id": "user2", "createdAt": "2024-09-10T10:45:25.069Z" },
                { "_id": "6", "post_id": "post2", "user_id": "user2", "createdAt": "2024-09-02T11:22:10.069Z" },
                { "_id": "6", "post_id": "post2", "user_id": "user2", "createdAt": "2024-09-05T11:22:10.069Z" },
                { "_id": "6", "post_id": "post2", "user_id": "user2", "createdAt": "2024-08-12T11:22:10.069Z" },
                { "_id": "6", "post_id": "post2", "user_id": "user2", "createdAt": "2024-07-14T11:22:10.069Z" },
                { "_id": "6", "post_id": "post2", "user_id": "user2", "createdAt": "2024-05-15T11:22:10.069Z" },
                { "_id": "6", "post_id": "post2", "user_id": "user2", "createdAt": "2024-04-13T11:22:10.069Z" },
                { "_id": "6", "post_id": "post2", "user_id": "user2", "createdAt": "2024-05-02T11:22:10.069Z" },
                { "_id": "7", "post_id": "post2", "user_id": "user2", "createdAt": "2024-05-15T12:15:10.069Z" },
                { "_id": "8", "post_id": "post2", "user_id": "user2", "createdAt": "2024-05-15T14:30:00.069Z" },
                { "_id": "9", "post_id": "post2", "user_id": "user2", "createdAt": "2024-09-18T15:20:20.069Z" }
            ]
        },
        {
            "post_id": "post3",
            "posts": [
                { "_id": "10", "post_id": "post3", "user_id": "user3", "createdAt": "2024-09-20T12:05:50.069Z" },
                { "_id": "11", "post_id": "post3", "user_id": "user3", "createdAt": "2024-09-21T13:15:45.069Z" },
                { "_id": "12", "post_id": "post3", "user_id": "user3", "createdAt": "2024-09-22T14:35:30.069Z" },
                { "_id": "13", "post_id": "post3", "user_id": "user3", "createdAt": "2024-09-22T16:00:00.069Z" },
                { "_id": "14", "post_id": "post3", "user_id": "user3", "createdAt": "2024-09-25T17:25:50.069Z" }
            ]
        },
        {
            "post_id": "post4",
            "posts": [
                { "_id": "15", "post_id": "post4", "user_id": "user4", "createdAt": "2024-09-28T14:44:30.069Z" },
                { "_id": "16", "post_id": "post4", "user_id": "user4", "createdAt": "2024-09-29T08:55:45.069Z" },
                { "_id": "17", "post_id": "post4", "user_id": "user4", "createdAt": "2024-09-29T11:30:10.069Z" },
                { "_id": "18", "post_id": "post4", "user_id": "user4", "createdAt": "2024-09-30T09:15:15.069Z" }
            ]
        },
        {
            "post_id": "post5",
            "posts": [
                { "_id": "19", "post_id": "post5", "user_id": "user5", "createdAt": "2024-09-02T10:30:00.069Z" },
                { "_id": "20", "post_id": "post5", "user_id": "user5", "createdAt": "2024-09-05T12:20:45.069Z" },
                { "_id": "21", "post_id": "post5", "user_id": "user5", "createdAt": "2024-09-10T09:00:00.069Z" },
                { "_id": "22", "post_id": "post5", "user_id": "user5", "createdAt": "2024-09-15T08:10:10.069Z" }
            ]
        },
        {
            "post_id": "post6",
            "posts": [
                { "_id": "23", "post_id": "post6", "user_id": "user6", "createdAt": "2024-09-07T07:50:10.069Z" },
                { "_id": "24", "post_id": "post6", "user_id": "user6", "createdAt": "2024-09-12T11:45:35.069Z" },
                { "_id": "25", "post_id": "post6", "user_id": "user6", "createdAt": "2024-09-20T13:25:25.069Z" }
            ]
        },
        {
            "post_id": "post7",
            "posts": [
                { "_id": "26", "post_id": "post7", "user_id": "user7", "createdAt": "2024-09-03T15:15:00.069Z" },
                { "_id": "27", "post_id": "post7", "user_id": "user7", "createdAt": "2024-09-09T10:30:25.069Z" },
                { "_id": "28", "post_id": "post7", "user_id": "user7", "createdAt": "2024-09-16T16:45:50.069Z" },
                { "_id": "29", "post_id": "post7", "user_id": "user7", "createdAt": "2024-09-23T09:55:15.069Z" },
                { "_id": "30", "post_id": "post7", "user_id": "user7", "createdAt": "2024-09-30T14:40:30.069Z" }
            ]
        },
        {
            "post_id": "post8",
            "posts": [
                { "_id": "31", "post_id": "post8", "user_id": "user8", "createdAt": "2024-09-04T09:25:45.069Z" },
                { "_id": "32", "post_id": "post8", "user_id": "user8", "createdAt": "2024-09-11T13:35:55.069Z" },
                { "_id": "33", "post_id": "post8", "user_id": "user8", "createdAt": "2024-09-18T10:05:05.069Z" },
                { "_id": "34", "post_id": "post8", "user_id": "user8", "createdAt": "2024-09-25T16:55:15.069Z" }
            ]
        }
    ]


    return (
        <div className={`dashboard-container flex flex-col gap-3 ${isMobile ? '!w-full' : '!w-[95%]'}`}>
            {isLoading === false ? (
                <>
                    <div className='heading flex justify-between'>
                        <h1>Welcome to your dashboard{username && (
                            <span className=''>, {username}</span>
                        )} 👋</h1>
                    </div>

                    <div className='flex flex-col md:flex-row gap-5'>
                        <div className='dashboard-section w-full md:w-1/3'>
                            <BarChart />
                        </div>
                        <div className={`${isMobile && ''} dashboard-section w-full md:w-2/3`}>
                            <Heatmap data={data} showWeekly={true} />
                        </div>
                    </div>

                    {/* GUIDE FOR NEW USERS */}
                    {userSettings && userSettings.showGuide === true && (
                        <Guide userSettings={userSettings} />
                    )}

                    <div className={`flex gap-3 ${isMobile ? 'flex-col' : 'justify-between'}`}>
                        <div className={`dashboard-section !p-0 !m-0 !h-[230px] flex flex-col items-center justify-center text-center bg-image-opacity relative ${isMobile ? 'w-full' : 'w-[20%]'}`}>
                            {/* Content inside the div */}
                            <h4 className='font-bold relative z-10'>Publish a post</h4>
                            <p className='text-sm p-3 relative z-10'>
                                Publish, schedule, or queue to engage your audience precisely when it matters most.
                            </p>
                            <div className={`shadow-lg shadow-blue-500/30 bg-blue-500 text-sm text-white p-3 hover:bg-blue-700 mx-auto font-semibold rounded-full cursor-pointer transition-all duration-200 flex items-center w-[max-content] justify-center relative z-10`}>
                                <Link to="/dashboard/upload" className="flex">
                                    Upload
                                    <FaPlus size={18} className="ml-2" />
                                </Link>
                            </div>
                        </div>

                        {/* Drafts section */}
                        <div className={`dashboard-section self-start ${isMobile ? 'w-full' : 'w-[58%]'}`}>
                            <Drafts onEditDraft={handleEditDraft} />
                        </div>

                        {userSettings && userSettings.showTodo === true && (
                            <div className={`${isMobile ? 'w-full' : 'w-[25%]'}`}>
                                <Todo />
                            </div>
                        )}
                    </div>

                    {/* ROW THREE SOCIALS */}
                    <div className='flex w-[100%]'>
                        <div className='flex-1'>
                            <Socials value={'dashboard'} />
                        </div>
                    </div>
                </>
            ) : (
                <div className='flex justify-center items-center w-full'>
                    <LoadingIndicator />
                </div>
            )}

            {showOnboardingModal && (
                <Onboarding onClose={onClose} />
            )
            }
        </div >
    );
}


export default Dashboard;