import { useState, useEffect, useRef } from "react";
import axios from 'axios'; // Import Axios
import Tabs from "../Navigation/MenuTabs/Tabs";
import { RiPencilLine, RiDeleteBinLine } from 'react-icons/ri';
import Pagination from "../Tools/Pagination";
import { Modal } from "../Modal/Modal";
import ContentLoader from 'react-content-loader';
import { BsInstagram, BsYoutube, BsTiktok } from 'react-icons/bs';
import { isMobile as checkIsMobile, handleResize, debounce } from '../../utils/utils'; // Import utility functions

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const Drafts = ({ onEditDraft }) => {
    const [drafts, setDrafts] = useState([]);
    const [activeTab, setActiveTab] = useState('draft') // Initialize the active tab
    const [currentPage, setCurrentPage] = useState(1);
    const [tabs, setTabs] = useState([
        { id: 'draft', label: 'Drafts', count: { length: 0, color: 'bg-blue-500' } },
        { id: 'scheduled', label: 'Scheduled', count: { length: 0, color: 'bg-yellow-500' } },
        { id: 'uploaded', label: 'History' },
    ]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [modalGroup, setModalGroup] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const hasRunOnceRef = useRef(false);
    const [isMobile, setIsMobile] = useState(checkIsMobile());

    useEffect(() => {
        const debouncedHandleResize = debounce(() => handleResize(setIsMobile), 100);
        window.addEventListener("resize", debouncedHandleResize);
        return () => {
            window.removeEventListener("resize", debouncedHandleResize);
        };
    }, [isMobile]);

    useEffect(() => {
        const getDrafts = async () => {
            try {
                const response = await axios.get(BACKEND_API_URL + '/api/auth/user/drafts', {
                    withCredentials: true,
                });
                const arr = response.data;
                setDrafts(arr);
                await updateTabCount(arr);
                setIsLoading(false);
            } catch (error) {
                console.log(error);
            }
        };

        if (!hasRunOnceRef.current && isDeleteModalOpen === false) {
            hasRunOnceRef.current = true;
            getDrafts();

        }

        if (isDeleteModalOpen === true) {
            hasRunOnceRef.current = false;

        }
    }, [isDeleteModalOpen]);

    const updateTabCount = async (mergedData) => {
        if (mergedData.length <= 0) return;

        const tabCounts = {
            draft: 0,
            scheduled: 0,
            failed: 0,
        };
        mergedData.forEach(group => {
            if (group.status === 'draft') {
                tabCounts.draft++;
            } else if (group.status === 'failed') {
                tabCounts.failed++;
            }
            else if (group.status === 'scheduled') {
                tabCounts.scheduled++;
            }
        });

        const hasFailedDrafts = tabCounts.failed > 0;

        setTabs(prevTabs => {
            const updatedTabs = [...prevTabs];

            if (!hasFailedDrafts) {
                setActiveTab('draft');
            } else {
                setActiveTab('failed');
                // Check if the "Failed" tab doesn't exist, add it as the first index
                if (!updatedTabs.find(tab => tab.id === 'failed')) {
                    updatedTabs.unshift({ id: 'failed', label: 'Failed', count: { length: 0, color: 'bg-red-500' } });
                }
            }

            return updatedTabs.map(tab => {
                if (tab.id in tabCounts) {
                    return { ...tab, count: { ...tab.count, length: tabCounts[tab.id] } };
                }
                return tab;
            });
        });
    };

    const handleTabChange = (newTab) => {
        setActiveTab(newTab); // Update the active tab when a tab is changed
        setCurrentPage(1); // Reset current page when tab changes
    };

    const paginate = () => {
        if (!drafts || drafts.length <= 0) return;
        const itemsPerPage = 3;
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;

        // Filter and sort drafts based on the latest updatedAt of their posts
        let sortedDrafts = drafts
            .map(group => {
                const latestPost = group.posts.reduce((latest, post) => {
                    return new Date(post.updatedAt) > new Date(latest.updatedAt) ? post : latest;
                }, group.posts[0]);
                return { ...group, latestUpdatedAt: new Date(latestPost.updatedAt) };
            })
            .sort((a, b) => b.latestUpdatedAt - a.latestUpdatedAt);

        // Filter drafts based on the active tab
        let filteredDrafts = sortedDrafts.map(group => ({
            ...group,
            posts: group.posts.filter(post => post.status === activeTab)
        })).filter(group => group.posts.length > 0);

        const maxPage = Math.ceil(filteredDrafts.length / itemsPerPage);

        const f = filteredDrafts.filter(post => post.status === activeTab);
        const currentItems = f.slice(indexOfFirstItem, indexOfLastItem);

        return { currentItems, maxPage };
    };

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= paginate().maxPage) {
            setCurrentPage(newPage);
        }
    };

    const [currentItems, setCurrentItems] = useState([]);
    const [maxPage, setMaxPage] = useState(1);
    useEffect(() => {
        if (drafts && drafts.length > 0) {
            const { currentItems, maxPage } = paginate();
            setCurrentItems(currentItems);
            setMaxPage(maxPage);
        } else {
            setCurrentItems([]);
            setMaxPage(1);
        }
    }, [drafts, activeTab, currentPage]);

    const handleDeleteButtonClick = (group) => {
        setModalGroup(group);
        setIsDeleteModalOpen(true);
    };

    const closeModal = () => {
        setModalGroup(null);
        setIsDeleteModalOpen(false);
    };

    return (
        <div className="flex flex-col">
            <Tabs tabs={tabs} onTabChange={handleTabChange} activeTab={activeTab} main={true} />

            {/* SHOW DRAFTS  */}
            {activeTab && (
                <div className="flex flex-col overflow-x-auto">
                    <table className="min-w-full table-fixed divide-y divide-gray-200 text-sm text-left">
                        <thead className="">
                            <tr>
                                <th className="whitespace-nowrap px-4 py-2 font-medium border-r border-gray-200 w-1/6">STATUS</th>
                                <th className="whitespace-nowrap px-4 py-2 font-medium border-r border-gray-200 w-4/6">Content</th>
                                <th className="whitespace-nowrap px-4 py-2 font-medium w-2/6">Media</th>
                            </tr>
                        </thead>

                        <tbody className="divide-y divide-gray-200 max-w-full">
                            {isLoading === false ? (
                                <>
                                    {currentItems && currentItems.length > 0 ? (
                                        currentItems.map((group, index) => (
                                            group.status === activeTab && (
                                                <GenerateTableRows
                                                    key={group.post_id}
                                                    group={group}
                                                    onEditDraft={onEditDraft}
                                                    handleDeleteButtonClick={handleDeleteButtonClick}
                                                    loading={isLoading}
                                                />
                                            )
                                        ))
                                    ) : (
                                        <tr>
                                            <td className={`whitespace-nowrap px-4 my-1 border-r`}></td>
                                            <td className={`whitespace-nowrap px-4 py-5 border-r italic text-lg`}>
                                                You have no {activeTab === 'history' ? 'uploaded' : activeTab} posts yet...
                                            </td>
                                        </tr>
                                    )}
                                </>
                            ) : (
                                <tr>
                                    <td colSpan="100%">  {/* Assuming you have a certain number of columns */}
                                        <Loader />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {/* Pagination */}
                    {maxPage > 1 && (
                        <Pagination currentPage={currentPage} maxPage={maxPage} onPageChange={handlePageChange} />
                    )}
                </div>
            )}
            {isDeleteModalOpen && (
                <Modal open={isDeleteModalOpen} closeModal={closeModal} type={'VerifyDelete'} otherInfo={modalGroup} />
            )}
        </div>
    )
}

const GenerateTableRows = ({ group, onEditDraft, handleDeleteButtonClick, loading }) => {
    const handleEditButtonClick = (clickPost) => {
        onEditDraft(clickPost);
    };

    const post = group.posts[0];
    return (
        <tr key={post} className={`${post.status === 'failed' ? 'bg-red-100' : ''} max-w-full`}>
            <td className={`whitespace-nowrap px-4 py-2 border-r border-gray-100 uppercase max-w-full ${post.status === 'failed' && 'text-red-400'}`}>
                <div className="flex flex-col items-center gap-1 w-max mx-auto">
                    <div className="flex justify-center gap-2">
                        {group.posts.map((groupPost, index) => (
                            <div key={index} className="flex gap-1">
                                <IconForSocialMedia socialMedia={groupPost.uploadTo} />
                            </div>
                        ))}
                    </div>
                    <div>
                        {post.status}
                    </div>
                    <div className="text-[10px] italic">
                        {post.status === 'scheduled' && (
                            <p className="text-[10px]">
                                {new Date(post.schedule).toLocaleString()}
                            </p>
                        )}
                        {post.status === 'uploaded' && (
                            <p className="text-[10px]">
                                {new Date(post.updatedAt).toLocaleString()}
                            </p>
                        )}
                    </div>
                </div>
            </td>
            <td className="whitespace-normal px-4 py-1 border-r border-gray-100 min-w-full">
                <p className="truncate font-semibold text-[15px] overflow-hidden max-w-[700px] w-[60%]">
                    {post.title}
                </p>
                <p className="text-sm text-clip truncate text-[15px] overflow-hidden max-w-[500px] w-[50%]">
                    {post.description}
                </p>
            </td>
            <td className="whitespace-normal flex items-center min-w-full justify-between gap-2">
                <div className="relative w-[70px] h-[80px] overflow-hidden flex-1">
                    <img
                        src={post.thumbnail && post.thumbnail !== '' ? post.thumbnail : `${process.env.PUBLIC_URL}/no-thumbnail.png`}
                        alt={post.title}
                        className="absolute min-w-full min-h-full object-cover"
                    />
                </div>

                <div className="flex flex-col">
                    <button
                        className="custom-button w-10"
                        title="Edit Product"
                        onClick={() => { handleEditButtonClick(group); }}
                    >
                        <RiPencilLine />
                    </button>
                    {post.status !== 'uploaded' && (
                        <button
                            className="custom-button w-10"
                            title="Delete Product"
                            onClick={() => handleDeleteButtonClick(group)}
                        >
                            <RiDeleteBinLine />
                        </button>
                    )}
                </div>
            </td>
        </tr>
    );
};

const Loader = () => (
    <ContentLoader
        speed={2}
        width={600}
        height={84}
        viewBox="0 0 600 84"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <rect x="15" y="15" rx="3" ry="3" width="120" height="12" />
        <rect x="15" y="40" rx="3" ry="3" width="400" height="12" />
        <rect x="15" y="65" rx="3" ry="3" width="80" height="12" />
    </ContentLoader>
);

const IconForSocialMedia = (socialMedia) => {
    const social = socialMedia.socialMedia;
    switch (social) {
        case 'youtube':
            return <BsYoutube color='red' size={13} />;
        case 'instagram':
            return <BsInstagram color='#C13584' size={13} />;
        case 'google' || 'youtube':
            return <BsYoutube color='red' size={13} />;
        case 'tiktok':
            return <BsTiktok color='black' size={13} />;
        default:
            return null; // Handle other cases or return a default icon
    }
}
export default Drafts;
