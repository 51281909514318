import React, { useState, useRef, useEffect } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import Textarea from '../../Tools/Textarea';
import Modal from '../../Modal/Modal';
import ThumbnailSelector from '../Thumbnail/Thumbnailselector'
import CheckboxBtn from '../Checkbox/Checkboxbtn'
import axios from 'axios';
import Info from '../../Tools/Info';
import ProgressWheel from '../../Tools/ProgressWheel';
import { IoMdArrowDropleft, IoMdArrowDropright } from 'react-icons/io';
import Tabs from '../../Navigation/MenuTabs/Tabs';
import { CiLogin } from "react-icons/ci";
import { v4 as uuidv4 } from 'uuid';
import { RiLoginBoxFill } from 'react-icons/ri';
const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const UploadForm = ({ draftFormData, loadingUpload, onFormAction, onUploadProgress, video }) => {
  const [openModal, setOpenModal] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const [userSocials, setUserSocials] = useState([]);
  const [atLeastOneSocialSelected, setAtLeastOneSocialSelected] = useState(false);
  const [activateUploadButton, setActivateUploadButton] = useState(false);
  const [uploadedToDrafts, setUploadedToDrafts] = useState(false);
  const [isModalScheduleOpen, setIsModalScheduleOpen] = useState(false);

  const [youtubeIsChecked, setYoutubeIsChecked] = useState(false);
  const [tiktokIsChecked, setTiktokIsChecked] = useState(false);
  const broadcastChannel = new BroadcastChannel('socialsChannel');
  const initialLoad = useRef(true);
  const isUpdatingUserSocials = useRef(true);
  const initialUserSocials = useRef(true);
  const uniquePostId = uuidv4();
  const [deactivatedFormData, setDeactivatedFormData] = useState([]);
  const [isDraftChecked, setIsDraftChecked] = useState(false);

  const siteMenuInitial = [
    { id: 'draft', label: 'Draft', icon: null }, // option when no socials connected
    { id: 'tiktok', label: 'Tiktok', icon: null },
    { id: 'youtube', label: 'Youtube', icon: null },
    { id: 'instagram', label: 'Instagram', icon: null },
  ];
  const [siteMenu, setSiteMenu] = useState([]);
  const [activeMenu, setActiveMenu] = useState('draft');
  const [draftPostId, setDraftPostId] = useState(null);


  const [formData, setFormData] = useState([
    {
      uploadTo: 'draft',
      draftId: '',
      platformId: '',
      postId: uniquePostId,
      title: '',
      description: '',
      videoPath: '',
      firebasePath: '',
      startDate: null,
      thumbnail: '',
      privacyStatus: 'public',
      commentEnabled: true,
      isContentDisclosed: false,
      duetEnabled: true,
      stitchEnabled: true,
      isBranded: false,
      isOwnBrand: false,
      hasBeenEdited: false,
    }
  ]);

  // GET edit draft data, schedule & draft or re-upload
  useEffect(() => {
    if (initialLoad.current && draftFormData && draftFormData.length > 0) {
      draftFormData = draftFormData.map((data) => ({
        ...data,
        postId: uniquePostId,
      }));
      setFormData(draftFormData)
      setDraftPostId(draftFormData[0].postId);
      insertDraftMenu(draftFormData);
      initialLoad.current = false;
    }
  }, [draftFormData]);

  const insertDraftMenu = (data) => {
    const containsDraftStatus = data.some(data => data.status === 'draft');
    if (containsDraftStatus) {
      setSiteMenu(prevSiteMenu => {
        const draftTab = siteMenuInitial.find(menuItem => menuItem.id === 'draft');
        if (draftTab && !prevSiteMenu.some(menuItem => menuItem.id === 'draft')) {
          return [...prevSiteMenu, draftTab];
        }
        return prevSiteMenu;
      });
    }
  }


  const handleSitieMenuChange = (newTab) => {
    setActiveMenu(newTab); // Update the active tab when a tab is changed
  };

  useEffect(() => {
    if (initialUserSocials.current) {
      getUserSocials();
      initialUserSocials.current = false;
    }
  }, []);

  useEffect(() => {
    const handleBroadcastMessage = async () => {
      try {
        broadcastChannel.onmessage = async (event) => {
          if (event.data === 'callbackLogin') {
            await getUserSocials();
            setOpenModal(false);
          }
        };
      } catch (error) {
        console.error('Error handling broadcast message:', error);
      }
    };

    handleBroadcastMessage();
  }, []);



  useEffect(() => {
    // Retrieve edit draft data from videouploader
    if (isUpdatingUserSocials.current && userSocials.length > 0 && draftFormData.length > 0) {
      isUpdatingUserSocials.current = false;
      const updatedUserSocials = userSocials.map(userSocial => {
        const isMatched = draftFormData.some(upload => upload.uploadTo === userSocial.platform);
        const isDraft = draftFormData.some(upload => upload.uploadTo === 'draft');

        if (userSocial.platform === 'youtube') {
          setYoutubeIsChecked(isMatched);
        }
        if (userSocial.platform === 'tiktok') {
          setTiktokIsChecked(isMatched);
        }
        if (isMatched || isDraft) {
          setActivateUploadButton(true);
          setAtLeastOneSocialSelected(true);
        }

        return { ...userSocial, checkbox: isMatched };
      });
      setUserSocials(updatedUserSocials);
    }

    // update sitemenu
    if (userSocials.length > 0) {
      const atLeastOneChecked = userSocials.some((social) => social.checkbox === true);
      let isDraft = false;

      if (draftFormData && draftFormData.length > 0) {
        isDraft = draftFormData.some(upload => upload.uploadTo === 'draft');
      }
      let updatedSiteMenu = [];

      if (atLeastOneChecked) {
        updatedSiteMenu = siteMenuInitial.filter(menuItem =>
          userSocials.some(social => social.platform === menuItem.id && social.checkbox === true)
        );
      }
      if (!atLeastOneChecked || isDraft) {
        const draftTab = { id: 'draft', label: 'Draft', icon: null };
        if (!updatedSiteMenu.some(menuItem => menuItem.id === 'draft')) {
          updatedSiteMenu.push(draftTab);
        }
      }
      setSiteMenu(updatedSiteMenu);

      if (updatedSiteMenu.length > 0) {
        setActiveMenu(updatedSiteMenu[0].id);
      } else {
        setActiveMenu('draft');
      }
    }
  }, [draftFormData, userSocials]);

  const getUserSocials = async () => {
    try {
      const response = await axios.get(BACKEND_API_URL + '/api/auth/user/social_media_tokens', {
        withCredentials: true,
      });

      response.data.forEach((item) => {
        item.checkbox = false;
      });

      for (const social of response.data) {
        if (social.platform === 'youtube') {
          setYoutubeIsChecked(social.checkbox);
        }
        if (social.platform === 'tiktok') {
          setTiktokIsChecked(social.checkbox);
        }
      }
      setUserSocials(response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching social media tokens:', error);
    }
  };
  const newFormDataRef = useRef(null);

  const closeModal = (action) => {
    if (action instanceof Date) {
      const newFormData = formData.map((data) => ({
        ...data,
        startDate: action,
      }));
      setFormData(newFormData)
      newFormDataRef.current = newFormData;
    }


    setOpenModal(false);
    setIsModalScheduleOpen(false);
  };

  // Monitor changes in formData to execute handleActionClick when the new startDate is set from modal
  // this ensures that it handles action after its set
  useEffect(() => {
    if (newFormDataRef.current) {
      handleActionClick('schedule');
      newFormDataRef.current = null; // Clear the ref after action is executed
    }

    //console.log(formData);
  }, [formData]);


  const handleFormDataChange = (platform, newFormData) => {
    setFormData(prevFormData =>
      prevFormData.map(data => {
        if (data.uploadTo === platform) {
          return { ...data, ...newFormData, hasBeenEdited: true };
        } else if (newFormData.thumbnail) {
          return { ...data, thumbnail: newFormData.thumbnail };
        } else {
          return data;
        }
      })
    );
  };

  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const handleUploadClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowDropdown(!showDropdown);
  };

  const handleClickOutside = (event) => {
    const uploadBtn = document.getElementById("uploadButton");
    let targetElement = event.target;
    let isInsideUploadButton = false;
    while (targetElement) {
      if (targetElement === uploadBtn) {
        isInsideUploadButton = true;
        break;
      }
      targetElement = targetElement.parentElement;
    }

    if (!isInsideUploadButton && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleActionClick = (action) => {
    //only send back formData that has been edited to save.
    let editedFormData = [];
    if (isDraftChecked === true) {
      editedFormData = formData.filter(data => data.hasBeenEdited);

    } else {
      editedFormData = formData.filter(data => data.hasBeenEdited && data.uploadTo !== 'draft' && data.status !== 'failed');
    }
    onFormAction(action, editedFormData);
  };


  const socialCheckboxesActivate = (userSocial) => {
    //update usersocials on checkboxbtn click
    setUserSocials((prevUserSocials) => {
      const updatedUserSocials = prevUserSocials.map((social) => {
        if (social.platform_id === userSocial.platform_id) {
          return { ...social, checkbox: !social.checkbox };
        }
        return social;
      });

      const atLeastOneChecked = updatedUserSocials.some((social) => social.checkbox === true);
      setAtLeastOneSocialSelected(atLeastOneChecked);
      setActivateUploadButton(atLeastOneChecked);

      if (userSocial.platform === 'youtube') {
        setYoutubeIsChecked(!userSocial.checkbox);
      }
      if (userSocial.platform === 'tiktok') {
        setTiktokIsChecked(!userSocial.checkbox);
      }

      // Update formData
      setFormData((prevFormData) => {
        // Handle checkbox activation
        if (!userSocial.checkbox) {
          const existingData = deactivatedFormData.find(data => data.uploadTo === userSocial.platform);
          if (existingData) {
            // If there was a previous deactivated social, activate it with previous values
            const existingObj = prevFormData?.find(data => data && data.uploadTo === userSocial.platform);
            if (!existingObj) {
              prevFormData = [...prevFormData, existingData];
              setDeactivatedFormData(prev => prev.filter(data => data.uploadTo !== userSocial.platform));
            }
          } else {
            // If no previous social form, create a new
            const existingObj = prevFormData?.find(data => data && data.uploadTo === userSocial.platform);
            if (!existingObj) {
              prevFormData = [...prevFormData, {
                uploadTo: userSocial.platform,
                draftId: '',
                postId: uniquePostId,
                title: '',
                description: '',
                videoPath: video.localVideo,
                firebasePath: video.firebasePath,
                startDate: null,
                thumbnail: video.thumbnail,
                privacyStatus: 'public',
                commentEnabled: true,
                isContentDisclosed: false,
                duetEnabled: true,
                stitchEnabled: true,
                isBranded: false,
                isOwnBrand: false,
                hasBeenEdited: false,
                platformId: userSocial.platform_id
              }];
            }
          }
        } else { // Handle checkbox deactivation
          const dataToDeactivate = prevFormData.find(data => data.uploadTo === userSocial.platform);
          if (dataToDeactivate) {
            setDeactivatedFormData(prev => [...prev, dataToDeactivate]);
            prevFormData = prevFormData.filter(data => data.uploadTo !== userSocial.platform);
          }
        }

        // Ensure there's always at least one object in formData
        if (prevFormData.length === 0) {
          prevFormData.push({
            uploadTo: 'draft',
            draftId: '',
            postId: uniquePostId,
            title: '',
            description: '',
            videoPath: '',
            firebasePath: '',
            startDate: null,
            thumbnail: '',
            privacyStatus: 'public',
            commentEnabled: true,
            isContentDisclosed: false,
            duetEnabled: true,
            stitchEnabled: true,
            isBranded: false,
            isOwnBrand: false,
            hasBeenEdited: false,
            platformId: '',
          });
        }

        prevFormData = prevFormData.map((data) => ({
          ...data,
          postId: uniquePostId,
        }));

        return prevFormData;
      });
      return updatedUserSocials;
    });
  };

  const findPlatformData = (platform) => {
    return formData.find(data => data.uploadTo === platform) || formData[0];
  };
  const draftSocialClick = () => {
    setIsDraftChecked((prevState) => {
      const newState = true;

      // Update siteMenu based on the new state of isDraftChecked
      setSiteMenu((prevSiteMenu) => {
        const draftTab = { id: 'draft', label: 'Draft', icon: null };

        if (newState) {
          // Add "draft" if it's not already in the menu
          if (!prevSiteMenu.some(menuItem => menuItem.id === 'draft')) {
            return [...prevSiteMenu, draftTab];
          }
        } else {
          // Remove "draft" if it exists in the menu
          const updatedSiteMenu = prevSiteMenu.filter(menuItem => menuItem.id !== 'draft');
          return updatedSiteMenu.length > 0 ? updatedSiteMenu : [draftTab];
        }

        return prevSiteMenu;
      });

      setAtLeastOneSocialSelected(true);
      return newState;
    });
  };

  return (
    <div className='upload-form h-full'>
      {/* SOCIAL MEDIA CHECKBOXES */}
      <div className={`flex w-full items-center justify-center flex-col gap-5 ${atLeastOneSocialSelected === false ? 'h-full' : ' '}`}>
        {atLeastOneSocialSelected === false && (
          <div className='flex items-center justify-between gap-5 font-semibold text-[25px] mb-5'>
            <p>Where do you want to publish?</p>
            <div onClick={(e) => setOpenModal(true)} className=' cursor-pointer hover:scale-110 transform'>
              <RiLoginBoxFill size={30} color='#0036a2' />
              <p className='text-[8px]'>Connect</p>
            </div>
          </div>
        )}
        <div className={`flex ${atLeastOneSocialSelected === false && 'mb-4 '}`}>
          {userSocials.length > 0 ? userSocials.map((socialMedia, index) => (
            <div
              key={index}
              className={`${socialMedia.checkbox ? 'opacity-100' : 'opacity-20'} ${firstRender && !socialMedia.checkbox && 'animate-custom-pulse animate-custom-pulse-delay'} mr-5 relative transition-transform transform hover:scale-105 `}
              onClick={(e) => socialCheckboxesActivate(socialMedia)}
            >
              <CheckboxBtn
                name={socialMedia.username}
                platform={socialMedia.platform}
                logo={socialMedia.logo}
                status={socialMedia.checkbox}
                firstSelect={!atLeastOneSocialSelected}
                todayButton="Today"
              />
            </div>
          )) : (
            <div className={`border border-gray-500 p-2 flex items-center px-2 rounded-lg cursor-pointer ${isDraftChecked ? 'opacity-100 bg-blue-400' : 'opacity-60'} ${firstRender && !isDraftChecked && 'animate-custom-pulse animate-custom-pulse-delay'} mr-5 relative transition-transform transform hover:scale-105`} onClick={(e) => draftSocialClick()}>
              Create a draft
            </div>
          )}

          {atLeastOneSocialSelected && (
            <div onClick={(e) => setOpenModal(true)} className='self-end cursor-pointer hover:scale-110 transform'>
              <CiLogin size={20} color='text-blue-300' />
              <p className='text-[9px]'>Connect</p>
            </div>
          )}
        </div>
      </div>

      <div className={`dashboard-section  ${atLeastOneSocialSelected === false ? 'hidden opacity-50 !cursor-not-allowed' : ' '}`}>
        <div className="flex flex-col relative mb-2">
          <Tabs tabs={siteMenu} onTabChange={handleSitieMenuChange} activeTab={activeMenu} main={true} />
        </div>

        <Form
          formData={findPlatformData(activeMenu)}
          onFormDataChange={(newFormData) => handleFormDataChange(activeMenu, newFormData)}
          youtubeIsChecked={activeMenu === 'youtube'}
          tiktokIsChecked={activeMenu === 'tiktok'}
          userSocials={userSocials}
          video={video}
        />

        <div className='flex border-t-2 pt-3 relative items-end'>
          <div className='flex-1 text-sm italic'>
            <p>Schedule date: <br /><span>{formData[0].startDate ? formData[0].startDate.toLocaleString().slice(0, -3) : 'Direct'}</span></p>
          </div>
          {loadingUpload === true ? (
            <ProgressWheel progress={onUploadProgress} />
          ) : (
            <>
              <div className='text-md mr-1 self-end text-sm italic'>
                {!uploadedToDrafts ? (
                  <>
                    <div className='flex flex-col items-center'>
                      {activateUploadButton === false && atLeastOneSocialSelected === false && (
                        <div className='group'>
                          <Info type="onlyDraftOption" />
                        </div>
                      )}
                      <p className='cursor-pointer' onClick={() => handleActionClick('draft')}>Save to draft</p>
                    </div>
                  </>
                ) : (
                  <>
                    <p className='text-green-700 italic font-light'>Saved successfully</p>
                  </>
                )}
              </div>

              <div className='relative ml-2'>
                <div className="flex w-full">
                  <div
                    id="uploadButton"
                    onClick={activateUploadButton === true && (userSocials && userSocials.length > 0) ? (e) => handleUploadClick(e) : null}
                    className={`flex rounded-sm h-[45px] w-[80px] items-center text-sm text-white font-bold justify-between hover:bg-blue-600 select-none ${activateUploadButton === true && (userSocials && userSocials.length > 0) ? 'cursor-pointer bg-blue-500' : 'cursor-not-allowed bg-gray-500'}`}
                  >
                    <div className='mx-auto'>Publish</div>

                    <div className='h-full  flex items-center'>
                      {showDropdown ? (
                        <IoMdArrowDropleft size={20} />
                      ) : (
                        <IoMdArrowDropright size={20} />
                      )}
                    </div>
                  </div>

                  {showDropdown && (
                    <div ref={dropdownRef} className="dropdown-content" onClick={(e) => e.stopPropagation()}>
                      <div onClick={(e) => handleActionClick('upload')} className=''>Direct</div>
                      <div onClick={(e) => setIsModalScheduleOpen(true)} className=''>Schedule</div>
                    </div>
                  )}
                </div>
                {tiktokIsChecked && formData.isContentDisclosed === true && !formData.isOwnBrand && !formData.isBranded && (
                  <p className="text-red-500 text-[10px] absolute w-[135px]">You need to indicate if your content promotes yourself or a third party</p>
                )}
              </div>
            </>
          )}
        </div>

        {tiktokIsChecked && (
          <div className="text-[11px] mt-2">
            {!formData.isBranded ? (
              <span>* By posting, you agree to TikTok's <a href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en" target="_blank" rel="noreferrer" style={{ color: "#FE2C55" }}>Music Usage Confirmation</a></span>
            ) : (
              <span>* By posting, you agree to TikTok's <a href="https://www.tiktok.com/legal/page/global/bc-policy/en" target="_blank" rel="noreferrer" style={{ color: "#FE2C55" }}>Brand Content Policy</a> and <a href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en" target="_blank" rel="noreferrer" style={{ color: "#FE2C55" }}>Music Usage Confirmation</a></span>
            )}
          </div>
        )}
      </div>

      {isModalScheduleOpen && (
        <div className='flex-col items-center border-t-2 pt-3 relative'>
          <Modal open={isModalScheduleOpen} type={'ScheduledModal'} otherInfo={formData[0].startDate} closeModal={closeModal} />
        </div>
      )}
      {openModal && (
        <div className='flex-col items-center border-t-2 absolute'>
          <Modal open={openModal} closeModal={closeModal} type={'ConnectSocialsModal'} otherInfo={'hashtag'} />
        </div>
      )}
    </div>
  );
};



const Form = ({
  formData,
  onFormDataChange,
  youtubeIsChecked,
  tiktokIsChecked,
  userSocials,
  video
}) => {
  const handleInputChange = (field, value) => {
    const newFormData = { [field]: value };
    onFormDataChange(newFormData);
  };

  const handleDescriptionChange = (value) => {
    handleInputChange('description', value);
  };

  const handleThumbnailSelect = (selectedThumbnail) => {
    handleInputChange('thumbnail', selectedThumbnail.imageUrl);
  };

  const toggleDisclosure = () => {
    if (!formData.isContentDisclosed) {
      handleInputChange('isOwnBrand', false);
      handleInputChange('privacyStatus', false);
    }
    handleInputChange('isContentDisclosed', !formData.isContentDisclosed);
  };

  const toggleBranded = () => {
    handleInputChange('isBranded', !formData.isBranded);
    if (!formData.isBranded) {
      handleInputChange('privacyStatus', 'public');
    }
  };

  return (
    <form onClick={(e) => e.stopPropagation()} className=''>
      <div className={``}>
        {youtubeIsChecked && (
          <label className="form-label">
            <input
              type="text"
              value={formData.title}
              onChange={(e) => handleInputChange('title', e.target.value)}
              className="form-input"
              placeholder={`YouTube Title`}
            />
          </label>
        )}
        {/* TEXT AREA */}
        <Textarea onDescriptionChange={handleDescriptionChange} initialDescription={formData.description} />

        {/* THUMBNAIL SELECTOR */}
        <ThumbnailSelector videoName={video.firebasePath} onThumbnailSelect={handleThumbnailSelect} draftThumbnail={video.thumbnail || null} />


        {/* TIKTOK PRIVACY SETTINGS */}
        <label className="flex flex-col font-semibold justify-start items-start mt-5">
          Privacy setting:
          <select
            value={formData.privacyStatus}
            onChange={(e) => handleInputChange('privacyStatus', e.target.value)}
            className="form-select"
          >
            <option value="private">Private</option>
            <option value="public">Public</option>
          </select>
        </label>

        {/* TIKTOK ALLOW USERS CHECKBOX */}
        {tiktokIsChecked === true && (
          <div className='flex flex-col py-3 bg-gray-200 rounded-lg px-2'>
            <div className='flex justify-between'>
              <p className='font-semibold mr-3'>Allow users to:</p>
              <div className='group'>
                <Info type="tiktokFeatures" />
              </div>
            </div>

            {userSocials && userSocials.map((item, index) => {
              if (item.platform === 'tiktok') {
                return (
                  <div className='flex space-x-3' key={index}>
                    <label className=''>
                      <input
                        type="checkbox"
                        checked={formData.commentEnabled}
                        onChange={(e) => handleInputChange('commentEnabled', e.target.checked)}
                      />
                      Comment
                    </label>
                    <br />
                    <label className={`relative group`}>
                      <input
                        type="checkbox"
                        checked={formData.duetEnabled}
                        onChange={(e) => handleInputChange('duetEnabled', e.target.checked)}
                      />
                      Duet
                    </label>
                    <br />
                    <label className={`relative group`}>
                      <input
                        type="checkbox"
                        checked={formData.stitchEnabled}
                        onChange={(e) => handleInputChange('stitchEnabled', e.target.checked)}
                        className={`item`}
                      />
                      <span>Stitch</span>
                    </label>
                  </div>
                );
              }
            })}

            <div className='flex flex-col mt-5'>
              <div className='flex relative'>
                <p className='flex-1 font-semibold'>Disclose video content:</p>
                <label className="switch relative">
                  <input type="checkbox" onChange={toggleDisclosure} checked={formData.isContentDisclosed} className="sr-only" />
                  <div className={`w-12 h-6 rounded-full shadow-md bg-gray-400 ${formData.isContentDisclosed && 'bg-green-500'}`}>
                    <div
                      className={`indicator w-6 h-6 rounded-full bg-white transform ${formData.isContentDisclosed ? 'translate-x-full' : 'translate-x-0'} transition duration-300 ease-in-out`}
                    ></div>
                  </div>
                </label>
              </div>
              <div>
                <p className='text-[10px]'>Turn on to disclose that this video promotes goods or services.</p>
              </div>
              {formData.isContentDisclosed && (
                <div className='flex flex-col transition duration-300'>
                  <div className='flex bg-blue-300 p-2 m-2 rounded-lg text-[12px]'>
                    <FaInfoCircle size={20} className='mr-2' />
                    <span>Your video will be labeled <span className='underline font-semibold'>Promotional Content</span>. This cannot be changed once your video is posted!</span>
                  </div>
                  <div className='flex-col w-[80%] mx-auto'>
                    <label className="flex">
                      <span className='flex-1 font-semibold'>Your Brand</span>
                      <input type="checkbox" onChange={(e) => handleInputChange('isOwnBrand', e.target.checked)} checked={formData.isOwnBrand} />
                    </label>
                    <p className='text-[12px]'>You are promoting yourself or your own business/brand. This video will be classified as <span className='font-semibold underline'>Brand Organic</span></p>
                  </div>
                  <div className='flex-col w-[80%] mx-auto my-3'>
                    <label className="flex">
                      <span className='flex-1 font-semibold'>Branded Content</span>
                      <input type="checkbox" onChange={toggleBranded} checked={formData.isBranded} />
                    </label>
                    <p className='text-[12px]'>You are promoting another brand or a third party. This video will be classified as <span className='font-semibold underline'>Branded Content</span>. <span className='text-red-300 underline'>Privacy setting will be set to public!</span></p>
                  </div>
                  <div>
                    <p className='text-[10px]'>* Your photo/video will be labeled as <span className='font-semibold underline'>Paid Partnership</span></p>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

export default UploadForm;
