import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaHome, FaClipboard, FaPlusCircle, FaUser, FaCog } from 'react-icons/fa';

const HorizontalMobileNavbar = () => {
    const location = useLocation();
    const currentPath = location.pathname;

    const isActive = (path) => {
        return currentPath === path ? 'text-blue-600' : 'text-gray-600';
    };

    return (
        <div className="sticky bottom-0 left-0 right-0 bg-white shadow-lg border-t border-gray-200">
            <div className="flex justify-around items-center h-16">
                <Link to="/dashboard" className={`flex flex-col items-center ${isActive('/dashboard')}`}>
                    <FaHome size={24} />
                    <span className="text-xs">Dashboard</span>
                </Link>
                <Link to="/dashboard/templates" className={`flex flex-col items-center ${isActive('/dashboard/templates')}`}>
                    <FaClipboard size={24} />
                    <span className="text-xs">Templates</span>
                </Link>
                <Link to="/dashboard/upload" className="relative">
                    <FaPlusCircle size={40} className="text-blue-600" />
                </Link>
                <Link to="/dashboard/socials" className={`flex flex-col items-center ${isActive('/dashboard/socials')}`}>
                    <FaUser size={24} />
                    <span className="text-xs">Socials</span>
                </Link>
                <Link to="/dashboard/settings" className={`flex flex-col items-center ${isActive('/dashboard/settings')}`}>
                    <FaCog size={24} />
                    <span className="text-xs">Settings</span>
                </Link>
            </div>
        </div>
    );
};

export default HorizontalMobileNavbar;
