import { useState, useEffect, useRef } from "react";
import Picker from '@emoji-mart/react'
import data from '@emoji-mart/data'
import { MdOutlineEmojiEmotions } from "react-icons/md";
import { GoMention } from "react-icons/go";
import { BsThreeDotsVertical } from "react-icons/bs";
import axios from 'axios'; // Import Axios
import { FaHashtag, FaRegClosedCaptioning } from "react-icons/fa";

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const Textarea = ({ onDescriptionChange, initialDescription }) => {
    const [description, setDescription] = useState(initialDescription);
    const [isPickerVisible, setIsPickerVisible] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [menuItems, setMenuItems] = useState(null);

    const handleInputChange = (e) => {
        const value = e.target.value;
        setDescription(value);

        // Invoke the callback function to pass the value back to the parent
        onDescriptionChange(value);
    };

    useEffect(() => {
        setDescription(initialDescription)
    }, [initialDescription])
    

    const handleInputChangeEmoji = (e) => {
        const updatedDescription = `${description} ${e.native}`;
        setDescription(updatedDescription);

        // Invoke the callback function to pass the value back to the parent
        onDescriptionChange(updatedDescription);
        setIsPickerVisible(false);
    };

    const handleMention = (e) => {
        const updatedDescription = `${description} @`;
        setDescription(updatedDescription);

        // Invoke the callback function to pass the value back to the parent
        onDescriptionChange(updatedDescription);
    };

    const handleThreeDots = async (e) => {
        try {
            const response = await axios.get(BACKEND_API_URL + '/api/auth/user/templates/hashtag', {
                withCredentials: true, // Include this option if you need cookies
            });
            setMenuItems(response.data)
        } catch (error) {
            console.log(error);
        }

        setIsMenuOpen(!isMenuOpen)
    };

    const handleMenuItemClick = (id) => {
        // Find the template with the matching ID
        const clickedTemplate = menuItems.find((template) => template._id === id);

        if (clickedTemplate) {
            var text = clickedTemplate.info
            setDescription(`${description + ' ' + text}`)
            onDescriptionChange(`${description + ' ' + text}`);
        }
        // Close the menu
        setIsMenuOpen(false);
    };
    const menuContainerRef = useRef(null); // Create a ref for the menu container

    // Add an event listener to detect clicks outside the menu
    useEffect(() => {
        function handleClickOutside(event) {
            if (menuContainerRef.current && !menuContainerRef.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        }

        // Attach the event listener to the document body
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Remove the event listener when the component unmounts
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="w-full border border-gray-300 rounded-md text-sm mb-4 font-cursive">
            <textarea
                className="resize-y w-full resize-none border-0 px-3 py-2 align-top sm:text-sm focus:outline-none"
                rows="4"
                placeholder="Write your caption..."
                onChange={handleInputChange}
                value={description}
            ></textarea>

            <div className="flex items-center gap-2 border-t-2 relative cursor-pointer">
                <div className={`rounded hover:bg-gray-100 pl-1 py-1.5 text-sm font-medium text-gray-700 hover:text-gray-600`} onClick={handleThreeDots}>
                    <BsThreeDotsVertical size={18} />
                </div>

                <div className={`rounded hover:bg-gray-100 py-1.5 text-sm font-medium text-gray-700 hover:text-gray-600`} onClick={(e) => setIsPickerVisible(!isPickerVisible)}>
                    <MdOutlineEmojiEmotions size={18} />
                </div>

                <div className={`rounded hover:bg-gray-100 pl-1 py-1.5 text-sm font-medium text-gray-700 hover:text-gray-600`} onClick={handleMention}>
                    <GoMention size={18} />
                </div>

                {isMenuOpen && (
                    <div ref={menuContainerRef} className="menu absolute top-[0] left-5 w-[150px] shadow-md rounded-md !z-10 py-2 bg-white">
                        {menuItems.reduce((acc, item) => {
                            if (item.template_type === 'hashtag') {
                                if (!acc.hasHashtag) {
                                    acc.hasHashtag = true;
                                    acc.elements.push(
                                            <div className="flex items-center gap-2 text-md font-bold mb-1 cursor-default pt-2 border-t border-gray-4 w-[90%] mx-auto">
                                                <FaHashtag size={12} />
                                                Hashtag
                                            </div>
                                    );
                                }
                            } else if (item.template_type === 'caption') {
                                if (!acc.hasCaption) {
                                    acc.hasCaption = true;
                                    acc.elements.push(
                                            <div className="flex items-center gap-2 text-md font-bold mb-1 cursor-default pt-2 border-t border-gray-4 w-[90%] mx-auto">
                                                <FaRegClosedCaptioning size={12} />
                                                Caption
                                            </div>                                 
                                    );
                                }
                            }

                            acc.elements.push(
                                <div
                                    key={item._id}
                                    className="menuItem cursor-pointer text-gray-800 hover:text-blue-500 py-2 w-[90%] mx-auto hover:inset-0 p-1 hover:bg-gray-100"
                                    onClick={() => handleMenuItemClick(item._id)}
                                >
                                    {item.name}
                                </div>
                            );

                            return acc;
                        }, { hasHashtag: false, hasCaption: false, elements: [] }).elements}
                        {/* Add more menu items as needed */}
                        {/* Add more menu items as needed */}
                    </div>
                )}

                <div className={`${isPickerVisible ? 'block' : 'hidden'} top-10 absolute bg-white z-10`}>
                    <Picker data={data} previewPosition="none" onEmojiSelect={(e) => handleInputChangeEmoji(e)} theme={'auto'} />
                </div>
            </div>
        </div>
    )
};

export default Textarea
