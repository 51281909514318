import React, { useState, useRef, useEffect, useCallback } from 'react';
import './VideoUploader.css'; // Import the CSS file for styling
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import './../firebase'
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

// Components
import Drafts from '../Drafts/Drafts';

import VideoPreview from './Preview/Preview';
import UploadForm from './UploadForm/UploadForm';
import sendEmail from '../Tools/SendEmail';
import VideoErrorUpload from '../../emails/VideoErrorUpload.tsx';
import { render } from '@react-email/components';
import LinkUpload from './LinkUpload/LinkUpload.js';
import CustomRadioButtons from '../Tools/CustomRadioButtons.js';

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const VideoUploader = ({ calendarDraft, showDrafts = true, calendarDate, closeScheduleModal }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const hasRunOnceRef = useRef(false);

  const [selectedVideo, setSelectedVideo] = useState(null); //uploadedVideo
  const [loadingUpload, setLoadingUpload] = useState(false);
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null);
  const [isVertical, setIsVertical] = useState(true);
  const [onUploadProgress, setOnUploadProgress] = useState(false);

  const [localVideoPath, setLocalVideoPath] = useState(null);
  const [firebaseVideoPath, setFirebaseVideoPath] = useState(null);
  const [firebaseThumbPath, setFirebaseThumbPath] = useState(null);
  const maxSizeBytes = 2 * 1024 * 1024 * 1024// 2 GB

  // RETURN FROM UPLOADFORM
  const [formData, setFormData] = useState({});

  //uploaded video status
  const handleEditDraft = useCallback(async (group) => {
    const updatedFormData = group.posts.map((data) => {
      const post = group.posts.find(post => post.uploadTo === data.uploadTo);

      if (post) {
        const draftScheduleDate = new Date(post.schedule);
        if (draftScheduleDate > new Date()) {
          post.schedule = draftScheduleDate;
        } else {
          post.startDate = null;
        }

        const newFormData = {
          draftId: post._id,
          postId: post.post_id,
          platformId: post.platform_id,
          title: post.title,
          description: post.description,
          privacyStatus: post.privacy_status,
          startDate: post.schedule,
          schedule: post.schedule,
          uploadTo: post.uploadTo,
          status: post.status,
          firebasePath: post.firebase_path,
          videoPath: post.video_path,
          hasBeenEdited: true,
        };
        setSelectedVideo(post.firebase_path);
        setFirebaseThumbPath(post.thumbnail);
        setFirebaseVideoPath(post.firebase_path);
        setLocalVideoPath(post.video_path);
        return newFormData;
      } else {
        return data;
      }
    });
    setFormData(updatedFormData);
  }, []);


  useEffect(() => {
    if (!hasRunOnceRef.current && calendarDraft) {

      //If its object its an scheduled draft, otherwise its a timestamp
      if (calendarDraft.posts.length > 0) {
        handleEditDraft(calendarDraft)
      }
      hasRunOnceRef.current = true;
    }
  }, [calendarDraft, handleEditDraft]);


  useEffect(() => {
    // If user re-uploads from history, a state is sent with the uploaded video, same process as draft to re-upload
    if (!hasRunOnceRef.current) {
      console.log(location.state);

      if (location.state && location.state.upload) {
        handleEditDraft(location.state.upload);
      }
      hasRunOnceRef.current = true;
    }
  }, [location, handleEditDraft]);



  useEffect(() => {
    if (file) {
      (async () => {
        try {
          await uploadFileToFirebase(file, 'video');
        } catch (error) {
          console.error('Failed to upload video to server:', error);
        }
      })();
    }
  }, [file]);



  /**
   * 
   * ON VIDEO UPLOAD, ONLY WORKS ON UPLOAD WITH A CLICK / NOT DRAG N DROP
   * 
   */
  const handleVideoChange = async (e) => {
    e.preventDefault();

    const file = e.target.files[0];

    if (file && file.size > maxSizeBytes) {
      toast.error('File size exceeds the maximum allowed size (2 GB)');
    } else {
      setSelectedVideo(URL.createObjectURL(file));
      setFile(file);
    }
  };


  const handleSelectButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();

    const files = e.dataTransfer.files;
    if (files[0].size > maxSizeBytes) {
      toast.error('File size exceeds the maximum allowed size (2 GB)');
    } else {
      setFile(files[0]); // Handle the dropped file(s) as needed
      setSelectedVideo(URL.createObjectURL(files[0]));
    }
  };

  const handleDragOver = (e) => {
    //prevent opening new window for preview on drag to element
    e.preventDefault();
  };

  /**
   * @function handleUploadButtonClick
   * UPLOAD button - Download it to server and upload to Social Medias.
   *
   */
  const handleUploadButtonClick = async (uploadData) => {
    const uploadPromises = [];
    let thumb = formData.thumbnail;

    try {
      if (!selectedVideo) {
        alert('Please select a video to upload.');
        return;
      }
      if (thumb && typeof thumb !== 'string') {
        thumb = await uploadFileToFirebase(thumb, 'img')
      }
      //check if social.checkbox == true which means that the user has checked the checkbox at videoUploader.
      if (uploadData.uploadTo) {
        switch (uploadData.uploadTo) {
          case 'youtube':
            uploadPromises.push(uploadToYoutube(uploadData));
            break;
          case 'tiktok':
            uploadPromises.push(uploadToTiktok(uploadData));
            break;
          case 'instagram':
            uploadPromises.push(uploadToInstagram(uploadData));
            break;
          // Add more cases for other platforms if needed
          default:
            break;
        }
      }
      // Run all uploads concurrently
      console.log(uploadData);
      navigate('/dashboard');
      const toastId = toast.loading('You post is being uploaded to ' + uploadData.uploadTo)

      // Run all uploads concurrently
      let promises = await Promise.all(uploadPromises);
      console.log(promises);
      if (promises && promises.length > 0 && promises[0] !== undefined) {
        toast.update(toastId, { render: 'Your content has been uploaded to ' + uploadData.uploadTo, type: "success", isLoading: false, autoClose: 5000 });
      } else {
        toast.update(toastId, { render: 'Failed to upload your content ' + uploadData.uploadTo, type: "error", isLoading: false, autoClose: 5000 });
      }

    } catch (error) {
      console.error('UPLOAD API ERROR:', error);
    }
  };

  /**
  * 
  * UPLOAD & REMOVE FROM SERVER
  */

  const uploadFileToFirebase = async (video_file, fileType) => {
    if (fileType === 'video') {
      setLoadingUpload(true);
    }

    const storage = getStorage();
    const folder = fileType === 'img' ? 'thumbnails/' : 'videos/';
    const fileName = new Date().getTime() + video_file.name;

    const storageRef = ref(storage, folder + fileName);
    const uploadTask = uploadBytesResumable(storageRef, video_file);

    try {
      const downloadURL = await new Promise((resolve, reject) => {
        uploadTask.on(
          'state_changed',
          (snapshot) => {
            if (fileType === 'video') {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setOnUploadProgress(Math.round(progress));
            }
          },
          (error) => {
            // Handle error
            console.log(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref)
              .then((url) => {
                if (fileType === 'video') {
                  setLoadingUpload(false);
                  setLocalVideoPath(folder + fileName);
                  setFirebaseVideoPath(url);
                  resolve(url);
                }
                if (fileType === 'img') {
                  setFirebaseThumbPath(url);
                  resolve(url);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        );
      });

      return downloadURL; // Return the download URL

    } catch (error) {
      // Handle any errors that occurred during the upload
      console.error('Upload failed:', error);
      //throw error; // Propagate the error
    }
  };

  /**
   * 
   * UPLOAD TO SOCIAL MEDIAS API
   */

  const uploadToYoutube = async (uploadData) => {
    let date;
    // Get current time since the api requires a date
    if (uploadData.startDate === null) {
      date = undefined;
    }
    else {
      date = uploadData.startDate.toISOString().split('.')[0] + 'Z';
    }
    const data = {
      title: uploadData.title,
      desc: uploadData.description,
      privacyStatus: uploadData.privacyStatus,
      schedule: date,
      platform_id: uploadData.platformId,
      postId: uploadData.postId,
      video_path: localVideoPath || formData.videoPath,
      firebasePath: firebaseVideoPath || formData.firebasePath,
      thumbnail: firebaseThumbPath || uploadData.thumbnail,
    };
    try {
      const response = await axios.post(BACKEND_API_URL + '/api/google/upload_video', { data }, { withCredentials: true });
      if (response.status === 201)
        //toast.success(`Your content has been uploaded to ${uploadData.uploadTo}!`);
        return response.data; // You can return the response data if needed
    } catch (error) {
      if (error) {
        handleErrorUpload(data);
      }
    }
  };

  const uploadToTiktok = async (uploadData) => {
    let privacy = '';
    if (uploadData.privacyStatus === "private") {
      privacy = "SELF_ONLY"
    } else if (uploadData.privacyStatus === "public") {
      privacy = "PUBLIC_TO_EVERYONE"
    }

    const data = {
      postId: uploadData.postId,
      description: uploadData.description,
      privStatus: privacy,
      video_path: localVideoPath || formData.videoPath,
      firebasePath: firebaseVideoPath || formData.firebasePath,
      thumbnail: firebaseThumbPath || uploadData.thumbnail,
      platform_id: uploadData.platformId,
      allowComment: uploadData.commentEnabled,
      allowDuet: uploadData.duetEnabled,
      allowStitch: uploadData.stitchEnabled,
      selfBrand: uploadData.isOwnBrand,
      contentBrand: uploadData.isBranded,
    };
    try {
      const response = await axios.post(BACKEND_API_URL + '/api/tiktok/init_upload', { data }, { withCredentials: true });
      if (response.status === 201)
        toast.success(`Your content has been uploaded to ${uploadData.uploadTo}!`);

      return response.data; // Return the data for chaining
    } catch (error) {
      if (error) {
        handleErrorUpload(data);
      }
    }
  };

  const uploadToInstagram = async (uploadData) => {
    const data = {
      title: uploadData.description,
      platform_id: uploadData.platformId,
      video_path: localVideoPath || formData.videoPath,
      firebasePath: firebaseVideoPath || formData.firebasePath,
      thumbnail: firebaseThumbPath || uploadData.thumbnail,
      postId: uploadData.postId
    };

    try {
      const response = await axios.post(BACKEND_API_URL + '/api/instagram/upload', { data }, { withCredentials: true });
      if (response.status === 201)
        toast.success(`Your content has been uploaded to ${uploadData.uploadTo}!`);

      return response.data; // Return the data for chaining
    } catch (error) {
      console.log(error);

      if (error) {
        handleErrorUpload(data);
      }
      //throw error; // Rethrow the error for proper error handling
      return;

    }
  };

  const handleErrorUpload = async (data) => {
    try {
      // Upload error to drafts
      const res = await axios.post(BACKEND_API_URL + '/api/auth/user/uploads/error', data, { withCredentials: true });
      if (res.status !== 201) return;

      // Fetch user settings
      const userSettingsResponse = await axios.get(BACKEND_API_URL + '/api/auth/user/settings', { withCredentials: true });
      if (userSettingsResponse.status !== 201) return;

      const userSettings = userSettingsResponse.data;
      // Check if notifications are enabled
      if (userSettings.notifications?.isActive && userSettings.notifications?.errorNotifications) {
        const emailHtml = render(<VideoErrorUpload upload={data} />);
        await sendEmail('Important: Video Upload Failed on SocialFlick', emailHtml);
      }
    } catch (error) {
      console.log(error);
    }

    // Log out from social
    if (data.uploadTo === undefined) return;
    const social = data.uploadTo;
    try {
      await axios.post(BACKEND_API_URL + '/api/auth/user/social/error', { social }, { withCredentials: true });
    } catch (error) {
      console.log(error);
    }
  };

  const handleVideoMetadata = () => {
    if (videoRef.current) {
      if (videoRef.current.videoWidth < videoRef.current.videoHeight) {
        setIsVertical(true)
      } else {
        setIsVertical(false);
      }
    }
  }


  const saveToDraft = async (isSchedule = false, uploadData) => {
    let thumb = uploadData.thumbnail;

    if (thumb && typeof thumb !== 'string') {
      thumb = await uploadFileToFirebase(uploadData.thumbnail, 'img')
    }
    const data = {
      id: uploadData.draftId || undefined,
      post_id: uploadData.postId,
      platform_id: uploadData.platformId,
      title: uploadData.title,
      description: uploadData.description,
      privacy_status: uploadData.privacyStatus || 'private',
      video_path: localVideoPath || formData.videoPath,
      firebase_path: firebaseVideoPath || formData.firebasePath,
      thumbnail: firebaseThumbPath || uploadData.thumbnail,
      status: isSchedule === true ? 'scheduled' : 'draft',
      uploadTo: uploadData.uploadTo,
      schedule: isSchedule === true ? uploadData.startDate : null
    };

    try {
      await axios.post(BACKEND_API_URL + '/api/auth/user/drafts', { data }, { withCredentials: true });
      if (isSchedule === true) {
        toast.success('Your post has been scheduled!');
        if (typeof closeScheduleModal === 'function') {
          closeScheduleModal();
        } else {
          navigate('/dashboard/calendar');
        }
      } else if (uploadData.draftId) {
        toast.success('Your post has been updated!');
        navigate('/dashboard');
      } else {
        toast.success('Your post has been saved to your drafts!');
        navigate('/dashboard');
      }

    } catch (error) {
      if (isSchedule === true) {
        toast.error('Failed to schedule your post, please try again!');
      } else {
        toast.error('Failed to save your post to drafts, please try again!');
      }
    }
  };



  const handleScheduleUpload = (uploadData, scheduleDate) => {
    saveToDraft(true, uploadData);
  };

  // UploadForm Action, and platforms objects data
  const handleFormAction = async (action, data) => {
    console.log(data);
    if (data && data.length > 0) {
      for (const platform of data) {
        if (action === 'upload') {
          handleUploadButtonClick(platform);
        } else if (action === 'schedule') {
          handleScheduleUpload(platform);
        } else if (action === 'draft') {
          saveToDraft(false, platform);
        }
      }
    }
  };

  const HandleVideoLinkUrl = (path, localPath) => {
    console.log(path);
    console.log(localPath);

    setSelectedVideo(path)
    setFirebaseVideoPath(path);
    setLocalVideoPath(localPath)
  };


  const [selectedUploadType, setSelectedUploadType] = useState('File');
  const handleSelectionChange = (newValue) => {
    setSelectedUploadType(newValue);
  };

  const radioContent = [
    {
      label: 'File',
      showCheckbox: false
    },
    {
      label: 'Url',
      showCheckbox: false,
    }
  ];

  return (
    <div className={`flex justify-center items-center ${!selectedVideo ? 'flex-col gap-5 pt-20' : 'flex-col md:flex-row'}`}>
      {/* FORM INPUTS - DO NOT SHOW THIS IF VIDEO HAS NOT BEEN UPLOADED*/}
      {selectedVideo && (
        <div className="left-side-container" style={{ minWidth: "400px", width: "40%" }}>
          <UploadForm
            draftFormData={formData}
            loadingUpload={loadingUpload}
            onFormAction={handleFormAction}
            onUploadProgress={onUploadProgress}
            video={{ localVideo: localVideoPath, firebasePath: firebaseVideoPath, thumbnail: firebaseThumbPath }}
          />

        </div>
      )}
      {/* DRAG & DROP VIDEO */}
      <div className={`right-side-container flex flex-col max-w-[100%] self-start md:self-center`}>
        {!selectedVideo && (
          <div className='min-w-full'>
            <div className="nav-menu flex w-max mx-auto mb-5">
              <CustomRadioButtons content={radioContent} onSelectionChange={handleSelectionChange} />
            </div>

            <div className="uploadMethods">
              {selectedUploadType === 'File' && (
                <div
                  className="upload-area"
                  onClick={handleSelectButtonClick}
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                >
                  <div className="flex flex-col items-center space-y-2 my-8">
                    <img
                      src={`${process.env.PUBLIC_URL}/random/upload.png`}
                      className="w-[20%]"
                      alt="Upload"
                    />
                    <div className="text-center">
                      <p className="font-semibold text-[20px]">Create your video upload</p>
                      <p className="text-[12px]">Drag & Drop or select a video file</p>
                    </div>
                  </div>
                  <p className="text-[10px] italic w-[150px] text-center">
                    Allowed *.mp4, *.AVI, *.MOV, *.WMV max size of 2GB
                  </p>
                  <input
                    ref={fileInputRef}
                    type="file"
                    accept="video/*"
                    onChange={handleVideoChange}
                    style={{ display: 'none' }}
                  />
                </div>
              )}

              {selectedUploadType === 'Url' && <LinkUpload onVideoPath={HandleVideoLinkUrl} />}
            </div>
          </div>

        )}

        {selectedVideo && (
          <VideoPreview
            selectedVideo={selectedVideo}
            isVertical={isVertical}
            handleVideoMetadata={handleVideoMetadata}
          />

        )}
      </div>



      {!selectedVideo && showDrafts && (
        <div className='dashboard-section !rounded-md !w-[1000px] !max-w-[100]'>
          <Drafts onEditDraft={handleEditDraft} />
        </div>
      )}

    </div>
  );
};
export default VideoUploader;