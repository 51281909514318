import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Onboarding from './Onboarding';
import axios from 'axios';
import { FaCheck } from 'react-icons/fa';
import { Modal } from '../Modal/Modal';
const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;


const Guide = ({ userSettings }) => {
    const [showOnboardingModal, setShowOnboardingModal] = useState(false);
    let [completedSteps, setCompletedSteps] = useState(0);
    let [showConfirmationModal, setShowConfirmationModal] = useState(false);
    let [userHidedGuide, setUserHidedGuide] = useState(false);

    let [completed, setCompleted] = useState([
        { step: 'onboarding', completed: false },
        { step: 'socials', completed: false },
        { step: 'video', completed: false },
        { step: 'schedule', completed: false },
        { step: 'templates', completed: false },
    ]);

    const totalSteps = 5;
    const hasRunOnceRef = useRef(false);

    const openOnboarding = () => {
        setShowOnboardingModal(true);
    }

    const handleCloseOnboarding = () => {
        setShowOnboardingModal(false);
    };

    useEffect(() => {
        const countCompletedSteps = completed.reduce((acc, step) => {
            if (step.completed) {
                return acc + 1;
            } else {
                return acc;
            }
        }, 0);

        setCompletedSteps(countCompletedSteps);
    }, [completed]);

    useEffect(() => {
        if (!hasRunOnceRef.current) {
            hasRunOnceRef.current = true;

            (async () => {
                try {
                    await checkUserOnboarding();
                    await checkSocials();
                    await checkDrafts();
                    await getTemplates();
                } catch (error) {
                    console.error('Failed to upload video to server:', error);
                }
            })();
        }

    }, []);

    const checkUserOnboarding = async () => {
        if (userSettings) {
            const userCompletedOnboarding = userSettings.onboarding.completed;
            if (userCompletedOnboarding) {
                const updatedSteps = [...completed];
                updatedSteps[0].completed = true;
                setCompleted(updatedSteps);
            }
        }
    };

    const checkSocials = async () => {
        try {
            const response = await axios.get(BACKEND_API_URL + '/api/auth/user/social_media_tokens', {
                withCredentials: true,
            });
            if (response.data.length > 0) {
                const updatedSteps = [...completed];
                updatedSteps[1].completed = true;
                setCompleted(updatedSteps);
            }
        } catch (error) {
            // Handle the error here
            console.error('Error fetching social media tokens:', error);
        }
    }

    const checkDrafts = async () => {
        try {
            const response = await axios.get(BACKEND_API_URL + '/api/auth/user/drafts', {
                withCredentials: true,
            });
            const hasUploadedDraft = response.data.some(draft => draft.status === 'uploaded');
            const hasScheduledDraft = response.data.some(draft => draft.status === 'schedule');
            const updatedSteps = [...completed];
            if (hasUploadedDraft) updatedSteps[2].completed = true;
            if (hasScheduledDraft) updatedSteps[3].completed = true;
            setCompleted(updatedSteps);
        } catch (error) {
            console.log(error);
        }
    };

    const getTemplates = async () => {
        try {
            const response = await axios.get(BACKEND_API_URL + '/api/auth/user/templates/hashtag', {
                withCredentials: true, // Include this option if you need cookies
            });
            if (response.data.length > 0) {
                const updatedSteps = [...completed];
                updatedSteps[4].completed = true;
                setCompleted(updatedSteps);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const calculateProgress = () => {
        const per = (completedSteps / totalSteps) * 100;
        return per.toFixed(0)
    };

    const closeModal = (confirm) => {
        setShowConfirmationModal(false);
        if (confirm === true) {
            setUserHidedGuide(true);
        }
    }

    return (
        <>
            {userHidedGuide === false && (
                <div className='flex flex-col dashboard-section'>
                    <div className='flex flex-col gap-2 mb-2'>
                        <div className='flex justify-between'>
                            <h2 className='border-b-2 border-blue-500 w-max'>Explore SocialFlick</h2>
                            <p className='text-[10px] italic text-red-500 hover:underline cursor-pointer' onClick={() => setShowConfirmationModal(true)}>Hide this guide</p>
                        </div>

                        <div className='flex justify-between'>
                            <p className='italic text-sm font-light mb-1'>Welcome to SocialFlick. Ready to skyrocket your social media presence? <span className='not-italic text-lg ml-1'>🚀</span></p>

                            <div className="flex flex-col w-[200px]">
                                <div className="text-xs font-semibold text-teal-600">
                                    Step {completedSteps}/{totalSteps}
                                </div>


                                <div className="flex items-center gap-2">
                                    <div className="flex-grow h-2 bg-teal-200 rounded-full overflow-hidden">
                                        <div
                                            style={{ width: `${calculateProgress()}%` }}
                                            className={`h-full bg-teal-500 transition-all duration-500 ease-in-out`}
                                        ></div>
                                    </div>
                                    <div className="text-xs font-semibold text-teal-600">
                                        {calculateProgress()}%
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className='flex overflow-auto gap-2 justify-between'>
                        {/* BOX 1 */}
                        <div onClick={openOnboarding} className={`--theme-colors box w-[15%] min-w-[200px] border hover:border-blue-500 flex flex-col relative ${completed[0].completed && 'opacity-40 hover:opacity-100'} cursor-pointer`}>
                            <div className='w-[100px] mx-auto py-2'>
                                <img src={`${process.env.PUBLIC_URL}/onboarding/guide/onboarding.png`} alt='onboarding' />
                            </div>
                            <div className='p-3 flex-1'>
                                <p className='font-semibold'>Complete onboarding</p>
                                <p className='flex-1 text-[11px] font-light'>To get started quickly and setup your account, finish our onboarding process.</p>
                            </div>

                            {completed[0].completed && (
                                <div className='absolute right-[-8px] top-[-7px]'>
                                    <FaCheck className="text-green-500" size={25} />
                                </div>
                            )}
                        </div>

                        <div className='self-center w-[30px]'>
                            <img src={`${process.env.PUBLIC_URL}/random/next.png`} alt='next' />
                        </div>


                        {/* BOX 2 */}
                        <Link to={'/dashboard/socials'} className={`--theme-colors box w-[15%] min-w-[200px] border hover:border-blue-500 flex flex-col relative ${completed[1].completed && 'opacity-40 hover:opacity-100'} cursor-pointer`}>
                            <div className='w-[100px] mx-auto py-2'>
                                <img src={`${process.env.PUBLIC_URL}/onboarding/guide/connect.svg`} alt='connect' />
                            </div>
                            <div className='p-3 flex-1'>
                                <p className='font-semibold'>Connect Socials</p>
                                <p className='text-[11px] font-light'>Connect and manage SocialFlick, to start uploading videos to all the connected socials simultaneously.</p>
                            </div>
                            {completed[1].completed && (
                                <div className='absolute right-[-8px] top-[-7px]'>
                                    <FaCheck className="text-green-500" size={25} />
                                </div>
                            )}
                        </Link>

                        <div className='self-center w-[30px]'>
                            <img src={`${process.env.PUBLIC_URL}/random/next.png`} alt='next' />

                        </div>

                        {/* BOX 3 */}
                        <Link to={'/dashboard/upload'} className={`--theme-colors box w-[15%] min-w-[200px] border hover:border-blue-500 flex flex-col relative ${completed[2].completed && 'opacity-40 hover:opacity-100'} cursor-pointer`}>
                            <div className='w-[100px] mx-auto py-2'>
                                <img src={`${process.env.PUBLIC_URL}/onboarding/guide/upload.svg`} className='w-[200px]' alt='upload' />
                            </div>

                            <div className='p-3 flex-1'>
                                <p className='font-semibold'>Upload a Video</p>
                                <p className='flex-1 text-[11px] font-light'>Start sharing your content in a flick, to all the connected socials.</p>
                            </div>
                            {completed[2].completed && (
                                <div className='absolute right-[-8px] top-[-7px]'>
                                    <FaCheck className="text-green-500" size={25} />
                                </div>
                            )}
                        </Link>

                        <div className='self-center w-[30px]'>
                            <img src={`${process.env.PUBLIC_URL}/random/next.png`} alt='next' />
                        </div>

                        {/* BOX 3 */}
                        <Link to={'/dashboard/schedule'} className={`--theme-colors box w-[15%] min-w-[200px] border hover:border-blue-500 flex flex-col relative ${completed[3].completed && 'opacity-40 hover:opacity-100'} cursor-pointer`}>
                            <div className='w-[100px] mx-auto py-2'>
                                <img src={`${process.env.PUBLIC_URL}/onboarding/guide/schedule.svg`} alt='schedule' />
                            </div>
                            <div className='p-3 flex-1'>
                                <p className='font-semibold'>Schedule A Video</p>
                                <p className='text-[11px] font-light'>Schedule your videos and post them exactly when you want them.</p>
                            </div>

                            {completed[3].completed && (
                                <div className='absolute right-[-8px] top-[-7px]'>
                                    <FaCheck className="text-green-500" size={25} />
                                </div>
                            )}
                        </Link>

                        <div className='self-center w-[30px]'>
                            <img src={`${process.env.PUBLIC_URL}/random/next.png`} alt='next' />
                        </div>

                        {/* BOX 3 */}
                        <Link to={'/dashboard/templates'} className={`--theme-colors box w-[15%] min-w-[200px] border hover:border-blue-500 flex flex-col relative ${completed[4].completed && 'opacity-40 hover:opacity-100'} cursor-pointer`}>
                            <div className='w-[100px] mx-auto py-2'>
                                <img src={`${process.env.PUBLIC_URL}/onboarding/guide/templates.svg`} alt='templates' />
                            </div>
                            <div className='p-3 flex-1'>
                                <p className='font-semibold'>Create templates</p>
                                <p className='text-[11px] font-light'>Create your own hashtag or caption templates to re-use on uploads.</p>
                            </div>
                            {completed[4].completed && (
                                <div className='absolute right-[-8px] top-[-7px]'>
                                    <FaCheck className="text-green-500" size={25} />
                                </div>
                            )}
                        </Link>

                    </div>


                    {showOnboardingModal && (
                        <Onboarding onClose={handleCloseOnboarding} />
                    )
                    }

                    {showConfirmationModal && (
                        <Modal open={showConfirmationModal} type={'ConfirmHideGuide'} closeModal={closeModal} />
                    )}
                </div>
            )}
        </>
    )
}

export default Guide;