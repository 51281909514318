import React, { useState, useRef, useEffect } from 'react';
import dayjs from 'dayjs'
import { Modal } from '../../../components/Modal/Modal';
import { MdCloudUpload } from 'react-icons/md';
import axios from 'axios';
import { BsInstagram, BsYoutube, BsTiktok } from 'react-icons/bs';
import { RiDeleteBinLine, RiPencilLine } from 'react-icons/ri';
import LoadingIndicator from '../../../components/Tools/LoadingIndicator';
import { LuRefreshCcw } from 'react-icons/lu';


const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

const Calendar = () => {
    const hasRunOnceRef = useRef(false)
    const [showHistory, setShowHistory] = useState(true);
    const [showScheduled, setShowScheduled] = useState(true);
    const [showLoading, setShowLoading] = useState(true);

    const [arrows, setArrows] = useState({
        left: false,
        right: false,
    });

    const getMonth = (month = dayjs().month()) => {
        month = Math.floor(month);
        const year = dayjs().year();
        const firstDayOfTheMonth = dayjs(new Date(year, month, 1)).day();
        let currentMonthCount = 0 - firstDayOfTheMonth;
        const daysMatrix = new Array(5).fill([]).map(() => {
            return new Array(7).fill(null).map(() => {
                currentMonthCount++;
                return dayjs(new Date(year, month, currentMonthCount));
            });
        });
        return daysMatrix;
    };
    const [currentMonth, setCurrentMonth] = useState(getMonth());
    const [monthIndex, setMonthIndex] = useState(new Date().getMonth());

    useEffect(() => {
        setCurrentMonth(getMonth(monthIndex));
    }, [monthIndex]);

    const handlePrevMonth = () => {
        const newMonthIndex = (monthIndex - 1 + 12) % 12; // Ensure the result is a positive number
        const threeMonthsBackwards = dayjs().subtract(3, 'months').month();
        if (newMonthIndex !== threeMonthsBackwards) {
            setMonthIndex(newMonthIndex);
            setArrows((prevState) => ({
                ...prevState,
                left: false,
                right: false,
            }));

        } else {
            setArrows((prevState) => ({
                ...prevState,
                left: true,
                right: false,
            }));
        }
    };

    const handleNextMonth = () => {
        const newMonthIndex = (monthIndex + 1) % 12;
        const threeMonthsAhead = dayjs().add(3, 'months').month();
        if (newMonthIndex !== threeMonthsAhead) {
            setMonthIndex(newMonthIndex);
            setArrows((prevState) => ({
                ...prevState,
                left: false,
                right: false,
            }));

        } else {
            setArrows((prevState) => ({
                ...prevState,
                left: false,
                right: true,
            }));
        }
    };


    const handleReset = () => {
        setMonthIndex(
            (prevMonthIndex) =>
                prevMonthIndex === dayjs().month() ? prevMonthIndex + Math.random() : dayjs().month()
        );
    };

    // Get uploads to show history uploads
    const [uploads, setUploads] = useState(null)

    useEffect(() => {
        if (!hasRunOnceRef.current) {
            hasRunOnceRef.current = true;
            getUploads();
        }
    }, [])

    const getUploads = async () => {
        try {
            const response = await axios.get(BACKEND_API_URL + '/api/auth/user/drafts', {
                withCredentials: true, // Include this option if you need cookies
            });
            const allUploaded = response.data.filter(item => item.status === "uploaded" || item.status === "scheduled");
            setUploads(allUploaded);
            setShowLoading(false);
        } catch (error) {
            console.log(error);
        }
    };
    const refreshUserUploads = () => {
        getUploads(); // Call your getUploads function to fetch updated data
    };

    return (
        <>
            {showLoading === false ? (
                <div className="flex flex-col w-full md:m-5 p-2 dashboard-section min-h-screen">
                    <CalendarHeader
                        monthIndex={monthIndex}
                        handlePrevMonth={handlePrevMonth}
                        handleNextMonth={handleNextMonth}
                        handleReset={handleReset}
                        arrowsStyle={arrows}
                        showHistory={showHistory}
                        showScheduled={showScheduled}
                        setShowHistory={setShowHistory}
                        setShowScheduled={setShowScheduled}
                    />

                    <div className="flex flex-1">
                        <Month month={currentMonth} userUploads={uploads} showHistory={showHistory} showScheduled={showScheduled} refreshUserUploads={refreshUserUploads} />
                    </div>
                </div>
            ) : (
                <div className='flex justify-center items-center w-full'>
                    <LoadingIndicator />
                </div>)}

        </>
    );
};


export default Calendar


const CalendarHeader = ({ monthIndex, handlePrevMonth, handleNextMonth, handleReset, arrowsStyle, showHistory, showScheduled, setShowHistory, setShowScheduled }) => {
    const currentMonthIndex = dayjs().month();

    return (
        <header className="py-2 flex justify-between items-center">
            <div className='hidden md:flex items-center'>
                <h1 className="text-[30px] text-gray-500 fond-bold">
                    Calendar
                </h1>
            </div>

            <div className='flex items-center gap-3'>
                <h2 className="ml-4 text-4xl text-gray-500 font-500">
                    {dayjs(new Date(dayjs().year(), monthIndex)).format(
                        "MMMM YYYY"
                    )}
                </h2>
                {monthIndex !== currentMonthIndex && (
                    <div
                        onClick={handleReset}
                        className=""
                    >
                        <LuRefreshCcw className='text-blue-300' />
                    </div>
                )}
            </div>

            <div className="ml-4 flex items-center">
                <div className="mr-5 flex items-center">
                    {/* On-Off Slider */}
                    <div className="relative flex items-center flex-col">
                        <div className="flex">
                            <div className="relative">
                                <details className="group [&_summary::-webkit-details-marker]:hidden">
                                    <summary
                                        className="flex cursor-pointer items-center gap-2 border-b border-gray-400 text-gray-900 transition hover:border-gray-600"
                                    >
                                        <span className="text-sm font-medium"> Show items? </span>

                                        <span className="transition group-open:-rotate-180">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth="1.5"
                                                stroke="currentColor"
                                                className="h-4 w-4"
                                            >
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                            </svg>
                                        </span>
                                    </summary>

                                    <div className="z-50 group-open:absolute group-open:start-0 group-open:top-auto group-open:mt-2">
                                        <div className="w-max rounded border border-gray-200 bg-white">
                                            <ul className="space-y-1 border-t border-gray-200 p-4">
                                                <li>
                                                    <label htmlFor="FilterInStock" className="inline-flex items-center gap-2">
                                                        <input type="checkbox" id="FilterInStock" className="h-5 w-5 rounded border-gray-300" onChange={() => setShowHistory(!showHistory)} checked={showHistory} />

                                                        <span className="text-sm font-medium text-gray-700 bg-green-300">Show previous uploads?</span>
                                                    </label>
                                                </li>

                                                <li>
                                                    <label htmlFor="FilterPreOrder" className="inline-flex items-center gap-2">
                                                        <input
                                                            type="checkbox"
                                                            id="FilterPreOrder"
                                                            className="h-5 w-5 rounded border-gray-300"
                                                            checked={showScheduled}
                                                            onChange={() => setShowScheduled(!showScheduled)}
                                                        />

                                                        <span className="text-sm font-medium text-gray-700 bg-yellow-300">Show scheduled uploads?</span>
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </details>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col w-[100px]'>

                    <div>
                        <button onClick={arrowsStyle.left ? null : handlePrevMonth} className={`bg-blue-200 border-none cursor-pointer p-2 focus:outline-none ${arrowsStyle.left && 'cursor-not-allowed hover:bg-blue-200'}`}>
                            <span className="cursor-pointer text-gray-600 mx-2">
                                {'<'}
                            </span>
                        </button>
                        <button onClick={arrowsStyle.right ? null : handleNextMonth} className={`bg-blue-200 border-none cursor-pointer p-2 focus:outline-none ${arrowsStyle.right && 'cursor-not-allowed hover:bg-blue-200'}`}>
                            <span className="text-gray-600 mx-2">
                                {'>'}
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </header>
    )
}


const Month = ({ month, userUploads, showHistory, showScheduled, refreshUserUploads }) => {
    return (
        <div className="flex-1 grid grid-cols-4 md:grid-cols-7">
            {month.map((row, i) => (
                <React.Fragment key={i}>
                    {row.map((day, idx) => (
                        <Day day={day} key={idx} rowIdx={i} userUploads={userUploads} showHistory={showHistory} showScheduled={showScheduled} refreshUserUploads={refreshUserUploads} />
                    ))}
                </React.Fragment>
            ))}
        </div>
    );
}
const Day = ({ day, rowIdx, userUploads, showHistory, showScheduled, refreshUserUploads }) => {
    const [showModal, setShowModal] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [matchingUploads, setMatchingUploads] = useState([]);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [clickedData, setClickedData] = useState({
        date: null,
        draft: null
    });
    const [deletedPost, setDeletedPost] = useState(null);

    const getCurrentDayClass = () => {
        return day.format("DD-MM-YY") === dayjs().format("DD-MM-YY")
            ? "text-white font-semibold rounded-full px-2 py-1 bg-blue-500"
            : "";
    };

    const isPastDay = () => {
        return day.isBefore(dayjs(), 'day');
    };

    const closeModal = () => {
        setShowModal(false);

        if (isDeleteModalOpen === true) {
            setDeleteModalOpen(false);
            refreshUserUploads();
        }

        if (showModal === true) {
            setShowModal(false);
            refreshUserUploads();
        }
    };

    useEffect(() => {
        if (userUploads && userUploads.length > 0) {
            const matchingUploadsArray = [];
            userUploads.forEach((upload) => {
                upload.posts.forEach((post) => {
                    const uploadDate = post.schedule ? new Date(post.schedule) : new Date(post.updatedAt);
                    const dayDate = new Date(day.$d);

                    if (
                        uploadDate.getFullYear() === dayDate.getFullYear() &&
                        uploadDate.getMonth() === dayDate.getMonth() &&
                        uploadDate.getDate() === dayDate.getDate()
                    ) {
                        matchingUploadsArray.push(upload);
                    }
                });
            });
            setMatchingUploads(matchingUploadsArray);
        }
    }, [userUploads, day]);
    const IconForSocialMedia = ({ socialMedia }) => {
        switch (socialMedia) {
            case 'youtube':
                return <BsYoutube color='red' size={13} />;
            case 'instagram':
                return <BsInstagram color='#C13584' size={13} />;
            case 'tiktok':
                return <BsTiktok color='black' size={13} />;
            default:
                return null;
        }
    };

    const handleUploadButtonClick = () => {
        setShowModal(true);
        setClickedData({ date: day.$d });
    };

    const handleEditButtonClick = (draft) => {
        setClickedData({ draft });
        setShowModal(true);
    };

    const handleDeleteButtonClick = (upload) => {
        setDeleteModalOpen(true);
        setDeletedPost(upload);
    };

    return (
        <div className={`relative border  min-h-[150px] border-gray-200 flex flex-col h-full ${isPastDay() ? 'opacity-30 bg-gray-200 border-gray-300 shadow-inner' : 'lg:hover:border-gray-400'}`} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <header className="flex flex-col items-center my-1">
                {rowIdx === 0 && (
                    <p className="text-sm mt-1">
                        {day.format("ddd").toUpperCase()}
                    </p>
                )}
                <p className={`text-sm text-center ${getCurrentDayClass()}`}>
                    {day.format("DD")}
                </p>
            </header>

            <div className={`flex-1 relative`}>
                <div className='flex flex-col overflow-y-auto max-h-[195px] overflow-x-hidden pb-5'>
                    {matchingUploads && matchingUploads.map((upload, index) => (
                        <div key={`${upload}`} className={`flex w-[95%] mx-auto rounded-lg mb-2 cursor-pointer ${upload.status === 'scheduled' ? 'bg-yellow-300 hover:bg-yellow-500' : 'bg-green-200 hover:bg-green-500'}  ${upload.status === 'scheduled' && !showScheduled && 'hidden'} ${upload.status === 'uploaded' && !showHistory && 'hidden'}`}>
                            <div className='flex-flex-col w-full'>
                                <div className='flex justify-between'>
                                    {upload.posts[0].title ? (
                                        <p className="text-xs mx-1 text-black font-bold truncate ">{upload.posts[0].title}</p>
                                    ) : (
                                        <p className='flex-1'></p>
                                    )}

                                    {upload.status !== 'scheduled' ? (
                                        <p className="text-black text-xs mr-1">
                                            {new Date(upload.posts[0].updatedAt).toLocaleTimeString('en-US', {
                                                hour12: false,
                                                hour: '2-digit',
                                                minute: '2-digit',
                                            })}
                                        </p>
                                    ) : (
                                        <p className="text-black text-xs mr-1">
                                            {new Date(upload.posts[0].schedule).toLocaleTimeString('en-US', {
                                                hour12: false,
                                                hour: '2-digit',
                                                minute: '2-digit',
                                            })}
                                        </p>
                                    )}
                                </div>
                                <div className='flex justify-between'>
                                    {upload.posts[0].description && (
                                        <p className="text-black text-xs mx-1 truncate">{upload.posts[0].description}</p>
                                    )}
                                    {upload.posts.map((post, i) => (
                                        <div className="flex gap-1">
                                            <IconForSocialMedia socialMedia={post.uploadTo} />
                                        </div>
                                    ))}
                                </div>

                                {upload.status === 'scheduled' && upload.posts[0].schedule && (
                                    <div className='flex justify-end'>
                                        <div
                                            className="p-1 transform"
                                            title="Edit Product"
                                            onClick={() => { handleEditButtonClick(upload); }}
                                        >
                                            <RiPencilLine className='hover:scale-110' />
                                        </div>
                                        <div
                                            className="p-1"
                                            title="Delete Product"
                                            onClick={() => handleDeleteButtonClick(upload)}
                                        >
                                            <RiDeleteBinLine className='hover:scale-110' />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {isHovered && !isPastDay() && (
                <div className="flex border-t border-gray-300 cursor-pointer text-[12px] justify-center items-center hover:text-blue-500 absolute bottom-.posts[0] bg-white w-full py-1" onClick={handleUploadButtonClick}>
                    Upload <MdCloudUpload size={20} color="black" className='ml-2' />
                </div>
            )}

            {showModal && (
                <Modal open={showModal} closeModal={closeModal} type={'VideoUploaderModal'} otherInfo={clickedData} />
            )}
            {isDeleteModalOpen && (
                <Modal open={isDeleteModalOpen} closeModal={closeModal} type={'VerifyDelete'} otherInfo={deletedPost} />
            )}
        </div>
    );
}
