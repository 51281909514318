import React, { useState, useEffect, useRef } from 'react';

const DropdownButton = ({ label, type, options }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    // Separate options and footer
    const menuOptions = options.filter(option => option.label);
    const footerOption = options.find(option => option.extra);

    return (
        <div className="relative" ref={dropdownRef}>
            {type === 'button' && (
                <button
                    type="button"
                    className={`inline-flex justify-center w-full rounded-md border shadow-sm px-4 py-2 text-sm font-medium text-white focus:outline-none ${isOpen ? 'bubble-border' : ''}`}
                    onClick={toggleDropdown}
                >
                    {label}
                </button>
            )}
            {type === 'singleRightItems' | type === 'singleLeftItems' && (
                <div
                    className={`p-2 focus:outline-none hover:bg-gray-200 hover:rounded-md ${isOpen && 'bg-gray-200 rounded-md'}`}
                    onClick={toggleDropdown}
                >
                    {label}
                </div>
            )}

            {isOpen && (
                <div className={`absolute right-0  mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50 ${type === 'button' || type === 'singleLeftItems' ? 'top-8' : 'md:right-[-200px] top-7 '}`}>
                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        {menuOptions.map((option, index) => (
                            <div
                                key={index}
                                className="block cursor-pointer px-4 py-2 text-sm hover:bg-gray-100 w-full text-left flex items-center"
                                role="menuitem"
                                style={{ color: option.color || 'gray' }} // Apply color dynamically
                                onClick={() => {
                                    option.onClick();
                                    setIsOpen(false);
                                }}
                            >
                                {option.icon && <span className="mr-2">{option.icon}</span>}
                                {option.label}
                            </div>
                        ))}
                        {footerOption && (
                            <div className="border-t border-gray-200 px-4 py-2 text-[10px] text-gray-500 mt-2">
                                {footerOption.extra}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default DropdownButton;
