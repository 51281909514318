import React, { useRef, useState } from 'react';
import Tabs from '../../Navigation/MenuTabs/Tabs';
import { FaComment, FaEye, FaInstagram, FaTiktok } from 'react-icons/fa';
import { LuRectangleVertical, LuRectangleHorizontal } from "react-icons/lu";

const VideoPreview = ({ selectedVideo, isVertical, handleVideoMetadata }) => {
  const videoRef = useRef(null);
  const [activeMenu, setActiveMenu] = useState('preview');
  const [activePreviewMenu, setActivePreviewMenu] = useState('instagram');
  const [activeSettingsTab, setActiveSettingsTab] = useState(isVertical ? 'vertical' : 'horizontal');
  const [videoLoaded, setVideoLoaded] = useState(false);

  const siteMenu = [
    { id: 'preview', label: 'Preview Post', icon: <FaEye /> },
    { id: 'comment', label: 'Comment', icon: <FaComment /> },
  ];

  const previewSocialsMenu = [
    { id: 'instagram', label: '', icon: <FaInstagram color='#cd486b' size={20} /> },
    { id: 'tiktok', label: '', icon: <FaTiktok color='black' size={20} /> },
  ];

  const previewSettingsMenu = [
    { id: 'vertical', label: 'Vertical', icon: <LuRectangleVertical color='red' size={20} /> },
    { id: 'horizontal', label: 'Horizontal', icon: <LuRectangleHorizontal color='black' size={20} /> },
  ];

  const handleSiteMenuChange = (newTab) => {
    setActiveMenu(newTab);
  };

  const handlePreviewMenuChange = (newTab) => {
    setActivePreviewMenu(newTab);
  };

  const handlePreviewSettingsChange = (newTab) => {
    setActiveSettingsTab(newTab);
  };

  const handleVideoLoad = () => {
    setVideoLoaded(true);
  };

  return (
    <div className="video-preview dashboard-section w-full mt-5">
      <div>
        <Tabs tabs={siteMenu} onTabChange={handleSiteMenuChange} activeTab={activeMenu} main={true} />
      </div>

      {activeMenu === 'preview' && (
        <div className="pt-5">
          {selectedVideo && (
            <div className="relative flex flex-col sm:flex-row">
              <div className={`relative flex justify-center ${activeSettingsTab === 'vertical' ? 'min-h-[500px] h-[500px]' : 'w-[400px] md:w-[600px] h-[300px]'}`}>
                <video
                  ref={videoRef}
                  onLoadedMetadata={handleVideoLoad}
                  controls
                  preload="auto"
                  className={`rounded-md border ${activeSettingsTab === 'vertical' ? 'w-[320px] sm:w-[320px]' : 'w-[400px] md:w-[600px]'}`}
                >
                  <source src={selectedVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                {activeSettingsTab === 'vertical' && activePreviewMenu && videoLoaded && (
                  <img src={`../svg/${activePreviewMenu}.svg`} alt="Overlay SVG" className="overlay-svg absolute top-0 left-0 w-full h-full pointer-events-none" />
                )}
              </div>
              {activeSettingsTab === 'vertical' && (
                <div className="absolute top-0 right-0 sm:top-[0px] right-[-100px] dashboard-section">
                  <Tabs tabs={previewSocialsMenu} onTabChange={handlePreviewMenuChange} activeTab={activePreviewMenu} main={false} useResponsiveDropdown={false} />
                </div>
              )}
              <div className="absolute bottom-0 right-0 sm:bottom-[0px] right-[-116px] !p-2 dashboard-section">
                <Tabs tabs={previewSettingsMenu} onTabChange={handlePreviewSettingsChange} activeTab={activeSettingsTab} main={false} useResponsiveDropdown={false} />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default VideoPreview;
