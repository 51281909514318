// Assets
import VideoUploader from '../../../../components/VideoUploader/VideoUploader';


const Upload = () => {
    return (
        <VideoUploader />
    )
}

export default Upload