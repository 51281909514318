import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './VerticalNavbar.css'; // Create a CSS file for styling
import { FaPlus } from 'react-icons/fa';
import { BiSolidDashboard, BiHistory, BiSolidHelpCircle } from 'react-icons/bi';
import { IoShareSocialSharp } from 'react-icons/io5';
import { IoIosSettings } from 'react-icons/io';
import { MdArrowBackIosNew, MdArrowForwardIos, MdOutlineDriveFolderUpload, MdOutlineCalendarMonth } from 'react-icons/md';
import { TbTemplate } from "react-icons/tb";


const VerticalNavbar = ({ checkLoginStatus }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const location = useLocation(); // Get the current location from React Router
  const [currentPath, setCurrentPath] = useState(null)
  const hasRunOnceRef = useRef(false);

  useEffect(() => {
    if (!hasRunOnceRef.current && location.pathname !== '') {
      setCurrentPath(location.pathname)
    }
  }, [location])



  const toggleNavbar = () => {
    setIsExpanded(!isExpanded);
  }

  useEffect(() => {
    // Set initial state based on screen width
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setIsExpanded(screenWidth > 768); // Adjust the breakpoint as needed
    };

    handleResize(); // Set initial state

    // Attach event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isActive = (currentPath, path) => {
    return currentPath === path ? 'active' : '';
  };


  return (
    <div className={`vertical-menu relative shadow-lg shadow-blue-800/70 ${isExpanded ? 'min-w-[250px] w-[250px] p-[20px]' : 'w-[80px]'} bg-gray-200 h-screen`}>

      <div className={`relative py-5 ${isExpanded ? 'custom-w-150 mx-auto' : 'custom-w-70 mx-auto pt-20'}`}>
        {isExpanded ? (
          <img src={`${process.env.PUBLIC_URL}/logo/logo-horizontal.png`} alt="Logo" />
        ) : (
          <img src={`${process.env.PUBLIC_URL}/logo/favicon-nobg.png`} alt="Logo" />
        )
        }
      </div>
      <div
        className={`px-2 py-1 text-white absolute right-0 top-10`}
        onClick={toggleNavbar}
      >
        {isExpanded ? (
          <MdArrowBackIosNew size={30} color="white" className="opacity-50 hover:border-gray-300 border-2 border-transparent rounded-xl p-1" />
        ) : (
          <MdArrowForwardIos size={30} color="white" className="opacity-50 hover:border-gray-300 border-2 border-transparent rounded-xl p-1" />
        )}
      </div>

      <div className={`${isExpanded ? 'pb-10' : 'mx-auto'} `}>
        <div className={`${isExpanded ? 'py-4 px-2' : 'p-3'} text-white bg-blue-500 hover:bg-blue-700 mx-auto font-bold rounded-full cursor-pointer flex items-center w-[80%] justify-center`}>
          <Link to="/dashboard/upload" className="flex ">
            {isExpanded ? 'Upload' : ''}
            <FaPlus size={24} color="white" className={`${isExpanded ? 'ml-3' : ''}`} />

          </Link>
        </div>

      </div>


      {/* OVERVIEW MENU */}

      <div className={`flex flex-col justify-center`}>
        <p className={`text-sm font-semibold mb-1 ${isExpanded ? '' : 'invisible w-0'}`}>OVERVIEW</p>

        <div className={`flex items-center ${isExpanded ? '' : 'justify-center'} ${isActive(currentPath, '/dashboard')} group relative`}>
          <Link to="/dashboard" className={`nav-link flex items-center gap-2  ${!isExpanded ? 'group' : ''}`}>
            <BiSolidDashboard size={`${isExpanded ? '24' : '35'}`} color="white" className={`navbar-icon`} />

            <span className={` ${!isExpanded ? 'text-sm italic text-sm italic hidden absolute top-2 right-[-65px] text-gray-500 group-hover:block' : ''}`}>Dashboard</span>
          </Link>
        </div>
      </div>


      {/* CONTENT MENU */}
      <div className={`flex flex-col justify-center ${isExpanded ? 'mt-10' : 'mt-1'}`}>
        <p className={`text-sm font-semibold mb-1 ${isExpanded ? '' : 'hidden w-0'}`}>CONTENT</p>

        <div className={`flex items-center ${isExpanded ? '' : 'justify-center'} group relative transition-transform transform hover:scale-110 hover:bg-gray-200 rounded-md mb-2`}>
          <Link to="/dashboard/upload" className={`nav-link flex items-center gap-2 ${isActive(currentPath, '/dashboard/upload')}  ${!isExpanded ? 'group' : ''}`}>
            <MdOutlineDriveFolderUpload size={`${isExpanded ? '24' : '35'}`} color="white" className={`navbar-icon`} />
            <span className={` ${!isExpanded ? 'text-sm italic text-sm italic hidden absolute top-2 right-[-65px] text-gray-500 group-hover:block' : ''}`}>Upload</span>
          </Link>
        </div>

        <div className={`flex items-center ${isExpanded ? '' : 'justify-center'} group relative transition-transform transform hover:scale-110 hover:bg-gray-200 rounded-md  mb-2`}>
          <Link to="/dashboard/schedule" className={`nav-link flex items-center gap-2 ${isActive(currentPath, '/dashboard/schedule')} ${!isExpanded ? 'group' : ''}`}>
            <MdOutlineCalendarMonth size={`${isExpanded ? '24' : '35'}`} color="white" className={`navbar-icon`} />

            <span className={` ${!isExpanded ? 'text-sm italic text-sm italic hidden absolute top-2 right-[-65px] text-gray-500 group-hover:block' : ''}`}>Calendar</span>
          </Link>
        </div>

        <div className={`flex items-center ${isExpanded ? '' : 'justify-center'} group relative transition-transform transform hover:scale-110 hover:bg-gray-200 rounded-md  mb-2`}>
          <Link to="/dashboard/templates" className={`nav-link flex items-center gap-2 ${isActive(currentPath, '/dashboard/templates')} ${!isExpanded ? 'group' : ''}`}>
            <TbTemplate size={`${isExpanded ? '24' : '35'}`} color="white" className={`navbar-icon`} />

            <span className={` ${!isExpanded ? 'text-sm italic text-sm italic hidden absolute top-2 right-[-65px] text-gray-500 group-hover:block' : ''}`}>Templates</span>
          </Link>
        </div>

        <div className={`flex items-center ${isExpanded ? '' : 'justify-center'} group relative transition-transform transform hover:scale-110 hover:bg-gray-200 rounded-md  mb-2`}>
          <Link to="/dashboard/posts" className={`nav-link flex items-center gap-2 ${isActive(currentPath, '/dashboard/posts')} ${!isExpanded ? 'group' : ''}`}>
            <BiHistory size={`${isExpanded ? '24' : '35'}`} color="white" className={`navbar-icon`} />

            <span className={` ${!isExpanded ? 'text-sm italic text-sm italic hidden absolute top-2 right-[-65px] text-gray-500 group-hover:block' : ''}`}>Posts</span>

          </Link>
        </div>

      </div>

      {/* CONFIG MENU */}
      <div className={`flex flex-col justify-end mb-[50px] flex-1`}>
        <p className={`text-sm font-semibold mb-1 ${isExpanded ? '' : 'invisible w-0'}`}>CONFIG</p>

        <div className={`flex items-center ${isExpanded ? '' : 'justify-center'} group relative transition-transform transform hover:scale-110 hover:bg-gray-200 rounded-md  mb-2`}>
          <Link to="/dashboard/socials" className={`nav-link flex items-center gap-2 ${isActive(currentPath, '/dashboard/socials')} ${!isExpanded ? 'group' : ''}`}>
            <IoShareSocialSharp size={`${isExpanded ? '24' : '35'}`} color="white" className={`navbar-icon`} />

            <span className={` ${!isExpanded ? 'text-sm italic text-sm italic hidden absolute top-2 right-[-65px] text-gray-500 group-hover:block' : ''}`}>Socials</span>

          </Link>
        </div>

        <div className={`flex items-center ${isExpanded ? '' : 'justify-center'} group relative transition-transform transform hover:scale-110 hover:bg-gray-200 rounded-md  mb-2`}>
          <Link to="/dashboard/settings" className={`nav-link flex items-center gap-2 ${isActive(currentPath, '/dashboard/settings')} ${!isExpanded ? 'group' : ''}`}>
            <IoIosSettings size={`${isExpanded ? '24' : '35'}`} color="white" className={`navbar-icon`} />

            <span className={` ${!isExpanded ? 'text-sm italic text-sm italic hidden absolute top-2 right-[-65px] text-gray-500 group-hover:block' : ''}`}>Settings</span>
          </Link>
        </div>

        <div className={`flex items-center ${isExpanded ? '' : 'justify-center'} group relative transition-transform transform hover:scale-110 hover:bg-gray-200 rounded-md mb-2`}>
          <Link to="https://socialflick.io/help-center" target="_blank" className={`nav-link flex items-center gap-2`}>
            <BiSolidHelpCircle size={`${isExpanded ? '24' : '35'}`} color="white" className={`navbar-icon`} />
            <span className={` ${!isExpanded ? 'text-sm italic text-sm italic hidden absolute top-2 right-[-65px] text-gray-500 group-hover:block' : ''}`}>Support</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default VerticalNavbar;